import React from "react";
import PropTypes from "prop-types";

import NavItem from "../nav_item";

const NavUser = ({ userName, siteName }) => {
  const initials = userName
    .toUpperCase()
    .split(" ")
    .map(w => w.charAt(0));
  const first = initials[0];
  const last = initials.length > 1 ? initials[initials.length - 1] : "";

  const formattedName =
    userName.length > 15 ? userName.slice(0, 13) + "..." : userName;

  return (
    <NavItem>
      <span className="NavUser_initials">{first + last}</span>
      <div className="NavUser_userWrapper">
        <span className="NavUser_fullName">{formattedName}</span>
        <span className="NavUser_siteName">{siteName}</span>
      </div>
    </NavItem>
  );
};

NavUser.propTypes = {
  userName: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired
};

NavUser.defaultProps = {
  userName: "",
  siteName: ""
};

export default NavUser;
