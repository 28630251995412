import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Message from "../message";

export class DefaultLayout extends PureComponent {
  constructor(props) {
    super(props);
  }

  static get propTypes() {
    return {
      navbar: PropTypes.node,
      hasMessages: PropTypes.bool,
      isBlueBackground: PropTypes.bool,
      isScrolling: PropTypes.bool,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ])
    };
  }

  render() {
    const {
      children,
      navbar,
      hasMessages,
      isBlueBackground,
      isScrolling
    } = this.props;

    const blueBackground = isBlueBackground
      ? "DefaultLayout_blueBackground"
      : null;

    const applyShadow = isScrolling ? "DefaultLayout_withNavbarShadow" : null;

    return (
      <div className="DefaultLayout">
        <div
          className={classnames(
            "DefaultLayout_topBar",
            blueBackground,
            applyShadow
          )}
        />
        <div className="DefaultLayout_container">
          {navbar}
          <div className="DefaultLayout_content">{children}</div>
        </div>
        {hasMessages ? <Message /> : null}
      </div>
    );
  }
}

export default DefaultLayout;
