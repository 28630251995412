import { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

export const HEADER_HEIGHT = 140;

/**
 * Get the current anchor.
 * It assumes that the URL has the form #/inventory#inventory_Repeaters
 * so a second anchor. This actually works,
 * but maybe we would think to change this and use a different character in
 * the future.
 * @returns {string}   the anchor, `null` otherwise.
 */
const getAnchor = () => {
  const hash = window.location.hash;
  const loc = hash.substring(1); // remove the first "#"
  // We assume that the URL has a second "anchor".
  if (loc.includes("#")) {
    const anchor = loc.split("#").pop();
    return anchor;
  }
  return null;
};

const gotoElement = (element, offset) => {
  // we need to go to the top to have the correct element position
  window.scrollTo(0, 0);
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition - offset;
  window.scrollTo(0, offsetPosition);
};

/**
 * Go to anchor, with an offset.
 * The offset is necessary becasue the menu is floating and is not counted
 * when `scrollTo`. Note that we cannot use `scrollIntoView` because we must have
 * this offset.
 * @param   {string}             id id of the element to jump to.
 * @param   {number}             offset the offset
 * @returns {undefined}
 */
const gotoAnchor = (id, offset) => {
  if (!id) {
    return;
  }
  const element = document.getElementById(id);
  if (!element) {
    // sometimes, the element is not rendered yet, so we retry in 500 milliseconds
    setTimeout(() => {
      const el = document.getElementById(id);
      if (el) {
        gotoElement(el, offset);
      }
    }, 500);
    return;
  }
  gotoElement(element, offset);
};

// see: https://reacttraining.com/react-router/web/guides/scroll-restoration
class ScrollToTop extends PureComponent {
  componentDidUpdate(prevProps) {
    const anchor = getAnchor();
    if (anchor) {
      // If there's an anchor in the route, follow it.
      // HEADER_HEIGHT is the height of the menu (so the offset we apply)
      // We could try to calculate this dynamically, but not sure is a good
      // idea.
      return gotoAnchor(anchor, HEADER_HEIGHT);
    }

    // ...otherwise, go to the top
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  static get propTypes() {
    return {
      location: PropTypes.shape({ hash: PropTypes.string }).isRequired,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ])
    };
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
