"use strict";

import { Map, List } from "immutable";
import {
  TF_THRESHOLDS_ADD,
  TF_THRESHOLDS_DEL,
  TF_THRESHOLDS_CLEAR,
  TF_CHOSEN_MIX_SET,
  TF_START_AT_SET,
  TF_CONCRETE_STATUS_SET,
  TF_CLEAR
} from "../actions";

const defaultState = Map({ thresholdIdSeq: 1, thresholds: List() });
const addThreshold = threshold => list => list.push(Map(threshold));
const delThreshold = id => list => list.filter(t => t.get("__id") !== id);
const incIdCounter = state =>
  state.set("thresholdIdSeq", state.get("thresholdIdSeq") + 1);

export default function reducer(
  state = defaultState,
  { type, threshold, id, chosenMix, startAt, concreteStatus }
) {
  switch (type) {
    case TF_THRESHOLDS_ADD: {
      const __id = state.get("thresholdIdSeq");
      const thresholdWithId = Object.assign({}, threshold, { __id });
      return incIdCounter(
        state.updateIn(["thresholds"], addThreshold(thresholdWithId))
      );
    }
    case TF_THRESHOLDS_DEL:
      return state.updateIn(["thresholds"], delThreshold(id));
    case TF_THRESHOLDS_CLEAR:
      return state.set("thresholds", List());
    case TF_CHOSEN_MIX_SET:
      return state.set("chosenMix", chosenMix);
    case TF_START_AT_SET:
      return state.set("startAt", startAt);
    case TF_CONCRETE_STATUS_SET:
      return state.set("concreteStatus", concreteStatus);
    case TF_CLEAR:
      return defaultState;
    default:
      return state;
  }
}
