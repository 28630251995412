import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import DefaultLayout from "./default_layout";
import TopNavbar from "./top_navbar";

export class BlueBackgroundLayout extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { isScrolling: false };
    this.listenToScroll = this.listenToScroll.bind(this);
  }

  static get propTypes() {
    return {
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ])
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll() {
    const isScrolling = document.documentElement.scrollTop > 0;
    if (isScrolling !== this.state.isScrolling) this.setState({ isScrolling });
  }

  render() {
    const isScrolling = this.state.isScrolling > 0;

    return (
      <DefaultLayout
        navbar={<TopNavbar />}
        hasMessages
        isBlueBackground
        isScrolling={isScrolling}
      >
        <div className="BlueBackgroundLayout_background" />
        {this.props.children}
      </DefaultLayout>
    );
  }
}

export default BlueBackgroundLayout;
