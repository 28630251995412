"use strict";

import { Map } from "immutable";
import moment from "moment";
import { SENSORS_ADD, SENSORS_SET, SENSORS_UPDATE } from "../actions";

const addRegisteredAtMoment = sensor => {
  // Precalculate moment for better performance when sorting.
  if (sensor.registeredAt) {
    return Object.assign({}, sensor, {
      registeredAtMoment: moment(sensor.registeredAt)
    });
  }
  return Object.assign({}, sensor);
};

const setter = (prev, sensor) =>
  prev.set(sensor.id, addRegisteredAtMoment(sensor));

export default function reducer(state = new Map(), { type, sensor, sensors }) {
  if (type === SENSORS_ADD) {
    return sensors.reduce(setter, state);
  }

  if (type === SENSORS_SET) {
    return sensors.reduce(setter, new Map());
  }

  if (type === SENSORS_UPDATE) {
    return state.mergeIn(
      [sensor.id],
      Object.assign({}, state.get(sensor.id), addRegisteredAtMoment(sensor))
    );
  }

  return state;
}
