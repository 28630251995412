import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userShape, siteShape } from "../types/shapes";
import { check } from "../feature_flag_rules";

export const FeatureFlag = ({ rules, user, site, children }) => {
  const allRulesPass = check(rules, user, site);
  if (allRulesPass) {
    return children;
  }
  return null;
};

FeatureFlag.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  rules: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ]).isRequired,
  user: userShape,
  site: siteShape
};

export const connectFn = ({ auth, settings }) => {
  const userDoesNotExist = !auth || !auth.get("user");
  const siteDoesNotExist = !settings || !settings.get("site");
  if (userDoesNotExist || siteDoesNotExist) return { user: null, site: null };

  // We need to convert these to JSON in order to
  // be able to have proper validation for the user
  // and site objects. Which are very important
  // considering the goal of having a FeatureFlag.
  const user = auth.get("user").toJSON();
  const site = settings.get("site").toJSON();

  // If site or user cannot be loaded,
  // the checks will default to `false`
  return { user, site };
};

export default connect(connectFn)(FeatureFlag);
