import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import classnames from "classnames";

import BlinkingRedDot from "../../blinking_red_dot";
import NavItem from "../nav_item";
import { withDevices } from "../../with_devices";

export class NavLink extends PureComponent {
  constructor(props) {
    super(props);
  }

  static get propTypes() {
    return {
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ]),
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
      className: PropTypes.string,
      isActive: PropTypes.bool,
      isMobile: PropTypes.bool,
      alert: PropTypes.node
    };
  }

  render() {
    const {
      url,
      target,
      alert,
      className,
      children,
      isActive,
      isMobile
    } = this.props;

    // we need to check if the url is external because react-router does not handle this
    const checkDomain = url => {
      if (url.indexOf("//") === 0) url = location.protocol + url;
      return url
        .toLowerCase()
        .replace(/([a-z])?:\/\//, "$1")
        .split("/")[0];
    };

    const isExternal = url => {
      return (
        url.indexOf(":") > -1 ||
        (url.indexOf("//") > -1 &&
          checkDomain(location.href) !== checkDomain(url))
      );
    };

    const activeName = isActive ? "NavLink_active" : null;

    return (
      <NavItem>
        {isExternal(url) ? (
          <a
            href={url}
            target={target}
            className={classnames("NavLink", className)}
          >
            {children}
          </a>
        ) : (
          <Link
            to={url}
            className={classnames("NavLink", className, activeName)}
            activeClassName={"NavLink_active"}
          >
            {children}
            {alert ? <BlinkingRedDot /> : null}
          </Link>
        )}
        {isMobile ? null : <div className="NavLink_alert">{alert}</div>}
      </NavItem>
    );
  }
}

export default withDevices(NavLink);
