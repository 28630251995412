import moment from "moment";
import { Iterable } from "immutable";

export const check = (rules, user, site) => {
  // This avoids breaking in case we have a single rule
  const ruleFunctions = [].concat(rules);
  return ruleFunctions.every(fn => {
    if (!user || !site) return false;
    return fn(user, site);
  });
};

// All rules here should take `user` and `site`
// as their first argument (in this order).
// This is what the `FeatureFlag` components passes
// down to them.

export const userIsSuperUser = user => {
  const { isSuperUser } = Iterable.isIterable(user) ? user.toJS() : user;
  return isSuperUser;
};

export const siteCreatedAfter = date => (_, site) => {
  const { createdAt } = Iterable.isIterable(site) ? site.toJS() : site;
  return moment(createdAt).isAfter(moment(date));
};

export const isDevEnv = () => {
  return process.env.NODE_ENV === "development";
};

export const isStagingEnv = () => {
  return process.env.NODE_ENV === "staging";
};

export const siteIsOneOf = siteIds => (_, site) => {
  const { id } = Iterable.isIterable(site) ? site.toJS() : site;
  return siteIds.includes(id);
};

export const siteHasOneOfNames = siteNames => (_, site) => {
  const { name } = Iterable.isIterable(site) ? site.toJS() : site;
  return siteNames.includes(name);
};

export const not = fn => {
  return (...args) => !fn.apply(null, args);
};

export const or = (...fns) => (...args) => {
  return fns.map(fn => fn.apply(null, args)).some(result => !!result);
};

// This object makes it easier to concatenate the rules
// for certain feature keys and import them elsewhere.
// It contains the "id" of a feature and an array of functions
// it should use to determine whether it should be on.
export const rules = {
  pours: or(
    siteIsOneOf([1, 156, 161]),
    siteCreatedAfter("2019-06-17T08:00:00.000Z")
  ),
  predictions: or(userIsSuperUser, siteIsOneOf([186])),
  apiToken: or(userIsSuperUser, siteIsOneOf([170, 182])),
  concretePerformance: userIsSuperUser
};

export default rules;
