"use strict";

import { fromJS } from "immutable";
import { AUTH_UPDATE } from "../actions";

const initialState = fromJS({ __status: "STATUS_PENDING" });

export default function reducer(state = initialState, action) {
  if (action.type === AUTH_UPDATE) return fromJS(action.auth);

  return state;
}
