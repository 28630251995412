"use strict";

import "babel-polyfill";
import React from "react";
import routes from "./routes";
import "react-dates/initialize";
import intercom from "./intercom";
import * as Rollbar from "./rollbar";
import * as tracking from "./track";
import * as pace from "imports?define=>false!pace-progress";

function main() {
  pace.start();

  if (
    process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV === "staging"
  ) {
    Rollbar.init();
    tracking.init();
  } else if (process.env.NODE_ENV === "development") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React);
  }

  // For support, add version into global scope
  if (window) window.CONVERGE_VERSION = process.env.COMMIT_SHA;

  routes(document.getElementById("app_converge"));
  intercom();
}

main();
