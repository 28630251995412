"use strict";

import debug from "debug";
import { store } from "./store/store";
import Rollbar from "rollbar-browser";

const log = debug("rollbar");

function userProfile(auth) {
  const user = auth.get("user");

  return {
    id: user.get("id"),
    email: user.get("email")
  };
}

export function init() {
  log("initialising rollbar");

  const accessToken = process.env.ROLLBAR_TOKEN;
  const { auth } = store.getState();

  const rollbarConfig = {
    accessToken,
    captureUncaught: true,
    payload: {
      isBeta: process.env.WHITE_VERSION,
      environment: process.env.NODE_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.COMMIT_SHA,
          guess_uncaught_frames: true
        }
      }
    }
  };

  if (auth.get("__status") === "STATUS_SAVED") {
    rollbarConfig.payload.person = userProfile(auth);
  }

  Rollbar.init(rollbarConfig);
}
