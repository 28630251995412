"use strict";

import { fromJS } from "immutable";
import { ALERTS_DISMISS, ALERTS_RESET } from "../actions";
import * as AVAILABLE_ALERTS from "../../constants/global_alerts";

const initialAlertsMap = fromJS(
  Object.keys(AVAILABLE_ALERTS).reduce((acc, key) => {
    return Object.assign({}, acc, { [key]: true });
  }, {})
);

export default function reducer(state = initialAlertsMap, { type, alertId }) {
  if (type === ALERTS_RESET) {
    return initialAlertsMap;
  }

  if (type === ALERTS_DISMISS) {
    return state.set(alertId, false);
  }

  return state;
}
