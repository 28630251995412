"use strict";

import { fromJS } from "immutable";
import { API_TOKEN_UPDATE } from "../actions";

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case API_TOKEN_UPDATE:
      return fromJS(action.token);

    default:
      return state;
  }
}
