"use strict";

import pick from "lodash/pick";
import partialRight from "lodash/partialRight";
import qs from "qs";
import * as actions from "./actions";
import { get, put, post, del, HttpError } from "../http";
import {
  selectTransform,
  selectMetric,
  selectUserSubscriptions
} from "./selectors";
import { isBetaEnvironment } from "../redirect_utils";
import { FS_IGNORED_ORGANISATION_IDS } from "../constants/configs";
import { Map } from "immutable";
import debug from "debug";
import moment from "moment";

const log = debug("action_creators");

//
// Consts
//

export const STATUS_PENDING = "STATUS_PENDING";
export const STATUS_SAVED = "STATUS_SAVED";
export const STATUS_ERROR = "STATUS_ERROR";
export const STATUS_IN_PROGRESS = "STATUS_IN_PROGRESS";
export const STATUSES = [
  STATUS_PENDING,
  STATUS_SAVED,
  STATUS_ERROR,
  STATUS_IN_PROGRESS
];

/**
 * Set the status of an object (not mutative)
 * @param  {object|Map} obj    object or immutable map to set status on
 * @param  {string}     status status string
 * @return {object}            new object with status set
 */
function setStatus(obj, status) {
  if (obj instanceof Map) {
    return obj.set("__status", status);
  } else {
    return Object.assign({}, obj, { __status: status });
  }
}

/**
 * Set the error of an object (not mutative)
 * @param  {object|Map} obj   object or immutable map to set error on
 * @param  {string}     error error message
 * @return {object}           new object with error set
 */
function setError(obj, error) {
  const __error = error && error.toString();
  if (obj instanceof Map) {
    return obj.set("__error", __error);
  } else {
    return Object.assign({}, obj, setStatus({ __error }, STATUS_ERROR));
  }
}

function _saveWithPending(
  dispatchUpdate,
  fallback,
  interim,
  endpoint,
  postParam
) {
  const pending = setStatus(interim, STATUS_PENDING);
  dispatchUpdate(pending);

  return post(endpoint, { body: postParam })
    .then(update => {
      // This function and its wrappers are intended for use on a single element
      // but the various posts can return one or multiple elements, so handle.
      const elements = update instanceof Array ? update : [update];
      elements.forEach(element => {
        const final = setStatus(element, STATUS_SAVED);
        dispatchUpdate(final);
      });
    })
    .catch(error => {
      if (!(error instanceof HttpError)) {
        throw error;
      }

      const final = setError(fallback, error.body);
      dispatchUpdate(final);
    });
}

//
// Auth
//

/**
 * Updates the current auth state
 * @param  {object}  auth  auth state
 * @return {object}        action
 */
export function authUpdate(auth) {
  return { type: actions.AUTH_UPDATE, auth };
}

/**
 * Attempt auth
 * @param  {object}  input  email/password object
 * @return {Function}       async dispatch function
 */
export function authAttempt(input) {
  input.email = input.email.trim().toLowerCase();

  // If the user is logging in from the beta environment  we set this user as a beta user
  // so that he always gets redirected to the beta environment when using old WEB versions.
  input.isBeta = isBetaEnvironment();

  return dispatch => {
    dispatch(authUpdate(setStatus({}, STATUS_IN_PROGRESS)));
    return put("/auth_tokens", { body: input, ignoreAuthErrors: true })
      .then(({ user, authToken, sites }) => {
        // set the sites list
        dispatch(setSites(sites));
        // default the user's active site to the first in their list
        dispatch(updateSiteSetting(sites[0]));
        // auth update MUST happen last. As soon as the user is
        // authenticated, the client will try to fetch all the data from the API,
        // but the site id won't yet be set, resulting in 400 errors, causing a
        // logout action. This would result in the user being stuck in a perpetual
        // loop.
        dispatch(authUpdate(setStatus({ user, authToken }, STATUS_SAVED)));
      })
      .catch(error => {
        let errorMessage;
        if (error.status) {
          if (error.status === 401) {
            errorMessage =
              "Incorrect email address or password; Please try again.";
            dispatch(authUpdate(setError({}, errorMessage)));
          } else {
            errorMessage = "Invalid user or password, please try again.";
            log({ error }, "auth error");
            dispatch(authUpdate(setError({}, errorMessage)));
          }
        } else {
          log({ error }, "server error");
          /* eslint-disable max-len */
          errorMessage =
            "Cannot connect to server: please check your internet connection, if the problem persists please get in touch.";
          dispatch(authUpdate(setError({}, errorMessage)));
        }

        dispatch(setMessage("error", "Oops!", errorMessage));
      });
  };
}

export const authLogout = (reloadPage = true) => (dispatch, getState) => {
  const { auth } = getState();

  // This needs to be reassigned to ensure that the reload will occur
  // in the same page as the user is logging out from, otherwise there
  // might be refreshes happening on top of wrong URLs. This is the case
  // of the app which uses `wvb://` to send messages
  const currentLocation = global.window.location.href;
  const reload = () =>
    // This can't be `setImmediate` to avoid errors when
    // clearing the state blocking the actual page reload.
    // Using `setImmediate` blocks the reload within the app.
    setTimeout(() => {
      if (!reloadPage) return null;
      global.window.location.assign(currentLocation);
      global.window.location.reload();
    }, 0);

  if (auth.get("__status") === STATUS_SAVED) {
    return del(`/auth_tokens/${auth.getIn(["authToken", "id"])}`)
      .then(() => authUpdate(setStatus({}, STATUS_PENDING)))
      .catch(e => authUpdate(setError({}, e)))
      .then(dispatch)
      .then(reload);
  } else {
    return Promise.resolve()
      .then(() => authUpdate(setStatus({}, STATUS_PENDING)))
      .then(dispatch)
      .then(reload);
  }
};

export const resetPasswordErrorReset = () => dispatch => {
  return Promise.resolve()
    .then(() => authUpdate(setStatus({}, STATUS_PENDING)))
    .then(dispatch);
};

export const removeThreshold = thresholdId => (dispatch, getState) => {
  const { thresholds } = getState();
  const newThresholds = thresholds.filter(t => t.id !== thresholdId);

  dispatch(setThresholds(setStatus(newThresholds, STATUS_PENDING)));

  return del(`/thresholds/${thresholdId}`)
    .catch(e => setThresholds(setError(newThresholds, e)))
    .then(() =>
      dispatch(setThresholds(setStatus(newThresholds, STATUS_SAVED)))
    );
};

//
// Settings
//

/**
 * Updates the duration setting in the UI
 * @param  {string} duration the new duration (e.g. "5 minutes")
 * @return {object}          action
 */
export function updateDurationSetting(duration) {
  if (typeof duration !== "string") {
    throw new TypeError("duration must be a string");
  }

  const isMomentDuration = /^(\d+)\s(second|minute|hour|day|week|month|year)(s?)/.test(
    duration
  );
  const isAllTime = duration === "all time";

  if (!isMomentDuration && !isAllTime) {
    throw new TypeError("duration is in the wrong format");
  }

  return { type: actions.SETTINGS_UPDATE_DURATION, duration };
}

/**
 * Updates the active site
 * @param  {string} site the new site
 * @return {object}      action
 */
export function updateSiteSetting(site) {
  return { type: actions.SETTINGS_UPDATE_SITE, site };
}

//
// Hubs
//

/**
 * Sets the streams list (clears any previous list)
 * @param  {array}  hubs an array of hubs to add
 * @return {object}      action
 */
export function setHubs(hubs) {
  return { type: actions.HUBS_SET, hubs };
}

/**
 * Fetch all hubs
 * @param  {Boolean}  forceRemote forces remote fetch
 * @return {Function}             async dispatch function (contacts server)
 */
export function getHubs(forceRemote) {
  return (dispatch, getState) => {
    if (!forceRemote && getState().hubs.size > 0) {
      return Promise.resolve();
    } else {
      return get("hubs")
        .then(setHubs)
        .then(dispatch)
        .catch(error => {
          if (!error.status) {
            throw error;
          } else if (error.status !== 401) {
            throw new Error(`unable to fetch hubs (status: ${error.status})`);
          }
        });
    }
  };
}

//
// Streams
//

/**
 * Sets the streams list (clears any previous list)
 * @param  {array}  streams an array of streams to add
 * @return {object}         action
 */
export function setStreams(streams) {
  return { type: actions.STREAMS_SET, streams };
}

/**
 * Fetch all streams
 * @param  {Boolean}  forceRemote forces remote fetch
 * @return {Function}             async dispatch function (contacts server)
 */
export function getStreams(forceRemote) {
  return (dispatch, getState) => {
    if (!forceRemote && getState().streams.size > 0) {
      return Promise.resolve();
    } else {
      return get("streams")
        .then(setStreams)
        .then(dispatch)
        .catch(error => {
          if (!error.status) {
            throw error;
          } else if (error.status !== 401) {
            throw new Error(
              `unable to fetch streams (status: ${error.status})`
            );
          }
        });
    }
  };
}

/**
 * Adds an array of streams to the list
 * @param  {array}  streams array of streams to add
 * @return {object}         action
 */
export function addStreams(streams) {
  return { type: actions.STREAMS_ADD, streams };
}

/**
 * Adds a single stream to the list
 * @param  {object} stream object literal containing stream properties
 * @return {object}        action
 */
export function addStream(stream) {
  let fixedStream = {};

  if (typeof stream.path === "string") {
    Object.assign(fixedStream, stream, { path: JSON.parse(stream.path) });
  } else {
    fixedStream = stream;
  }

  return addStreams([fixedStream]);
}

/**
 * Updates a sensor on the server
 * @param   {object}  data the sensor to be updated with the properties
 *                    of the sensor to update (merged in)
 * @return {object}   action
 */
export function saveSensor(data) {
  return (dispatch, getState) => {
    const original = getState().sensors.get(data.id) || data;
    const dispatchUpdate = sensor => dispatch(updateSensor(sensor));
    return _saveWithPending(dispatchUpdate, original, data, "sensors", data);
  };
}

/**
 * Sets the archive status of a sensor
 * @param  {string}  sensorId the id of the sensor
 * @param  {boolean} archive whether you want the sensor archived
 * @return {object}  action
 */
export function setSensorArchivedStatus(sensorId, archive) {
  return (dispatch, getState) => {
    const original = getState().sensors.get(sensorId);
    const dispatchUpdate = sensor => dispatch(updateSensor(sensor));
    const archivedAt = archive ? moment().toISOString() : null;
    const interim = Object.assign({}, original, { archivedAt });
    return _saveWithPending(
      dispatchUpdate,
      original,
      interim,
      `sensors/${sensorId}/archive`,
      {
        archive
      }
    );
  };
}

//
// Data
//

/**
 * Sets the data for this stream (clears any previous data for this stream)
 * @param  {string} streamId the id of the stream
 * @param  {array}  data     data array
 * @return {object}          action
 */
export function setData(streamId, data) {
  return { type: actions.DATA_SET, streamId, data };
}

/**
 * Get data for a given stream
 *
 * @param {string} streamId the id of the stream
 * @param {object} params parameters
 * @param {string} params.startDate start date of range
 * @param {string} params.endDate end date of range
 * @param {string} params.duration duration of range (e.g. "5 minutes")
 * @param {number} params.limit limit the number of records to fetch
 * @returns {Function} async dispatch function (queries API)
 */
export function getDataForStream(streamId, params = {}) {
  log("Fetching data for %s with params %o", streamId, params);
  // check conditions are valid
  if (!params.duration) {
    if (!params.startDate || !params.endDate) {
      throw new TypeError(
        "Both startDate and endDate are required in the " +
          "absence of a duration"
      );
    }
  }

  // setup cursors for pagination
  let cStartDate,
    cEndDate,
    i = 0;

  // setup compiled data array
  let compiled = [];

  const fetchLoop = () => {
    const fetchParams = {};
    if (i === 0) {
      // only include the duration on the first loop
      fetchParams.duration = params.duration;
      fetchParams.startDate = params.startDate;
      fetchParams.endDate = params.endDate;
    } else {
      fetchParams.startDate = cStartDate;
      fetchParams.endDate = cEndDate;
    }
    fetchParams.limit = params.limit;

    return get(`streams/${streamId}/data?${qs.stringify(fetchParams)}`).then(
      ({ data, meta }) => {
        // concat data
        compiled = compiled.concat(data);
        // set cursors
        cStartDate = meta.targetStartDate;
        cEndDate = meta.startDate;
        i++;

        return meta.count < meta.limit ? true : fetchLoop();
      }
    );
  };

  return dispatch => {
    return fetchLoop().then(() =>
      dispatch(setData(streamId, compiled.reverse()))
    );
  };
}

/**
 * Get latest data point for a given stream.
 * @param {string} streamId the id of the stream.
 * @returns {Function} async dispatch function (queries API)
 */
export function getLatestDatumForStream(streamId) {
  log("Fetching latest datum for %s", streamId);

  const fetchParams = {};
  fetchParams.duration = "1 year";
  fetchParams.limit = 1;

  return dispatch => {
    return get(`streams/${streamId}/data?${qs.stringify(fetchParams)}`).then(
      ({ data }) => {
        dispatch(addLastDatum(data[0]));
      }
    );
  };
}

/**
 * Adds a last datum to state.
 * @param  {object} datum object literal containing data point properties
 * @return {object}       action
 */
export function addLastDatum(datum) {
  return { type: actions.LASTDATUM_ADD, datum };
}

/**
 * Reset lastData
 * @return {object}       action
 */
export function clearLastData() {
  return { type: actions.LASTDATA_CLEAR };
}

/**
 * Adds an array of data points to the state list
 * @param  {array}  data array containing data points
 * @return {object}      action
 */
export function addData(data) {
  return { type: actions.DATA_ADD, data };
}

/**
 * Adds a single datum to the state list
 * @param  {object} datum object literal containing data point properties
 * @return {object}       action
 */
export function addDatum(datum) {
  return addData([datum]);
}

//
// Sensors
//

/**
 * Adds sensors to the list
 * @param  {object} sensors object literal containing sensor properties
 * @return {object}         action
 */
export function addSensors(sensors) {
  return { type: actions.SENSORS_ADD, sensors };
}

/**
 * Adds a single sensor to the list
 * @param  {object} sensor object literal containing sensor properties
 * @return {object}        action
 */
export function addSensor(sensor) {
  return addSensors([sensor]);
}

/**
 * @param  {array} sensors array of sensors
 * @return {object}        action
 */
export function setSensors(sensors) {
  return { type: actions.SENSORS_SET, sensors };
}

/**
 * Updates the properties of a given sensor
 * @param  {object} sensor properties of the sensor
 * @return {object}        action
 */
export function updateSensor(sensor) {
  return { type: actions.SENSORS_UPDATE, sensor };
}

/**
 * Sets the pending-sensor data
 * @param  {object} sensor sensor data
 * @return {object}        action
 */
export function setNewSensor(sensor) {
  return { type: actions.SENSORS_NEW_SET, sensor };
}

/**
 * Registers a new sensor on the server (and adds it to the state)
 * @param  {object}   data sensor registration data
 * @return {Function}      async dispatch function (saves data on API)
 */
export function registerSensor(data) {
  return (dispatch, getState) => {
    const newData = setStatus(data, STATUS_PENDING);

    dispatch(setNewSensor(newData));

    return post(`sensors/${newData.id}/register`, { body: data })
      .then(function(sensor) {
        const { stream } = sensor;
        delete sensor.stream;

        const finalSensor = setStatus(sensor, STATUS_SAVED);
        const finalStream = Object.assign(setStatus(stream, STATUS_SAVED), {
          metric: selectMetric(getState(), stream.metricId)
        });

        dispatch(addSensor(finalSensor));
        dispatch(setNewSensor(finalSensor));
        dispatch(addStream(finalStream));
      })
      .catch(function(error) {
        if (!(error instanceof HttpError)) {
          throw error;
        }

        const errorMessage =
          error.status === 410
            ? JSON.parse(error.body).message
            : error.body || error;

        const finalSensor = setError(data, errorMessage);

        dispatch(setNewSensor(finalSensor));

        // TODO set global error instead of returning finalSensor
        // Please see sensor_registration_view
        return finalSensor;
      });
  };
}

/**
 * Fetch all sensors
 * @param  {Boolean}  forceRemote forces remote fetch
 * @return {Function} async dispatch function (contacts server)
 */
export function getSensors(forceRemote) {
  return (dispatch, getState) => {
    if (!forceRemote && getState().sensors.size > 0) {
      return Promise.resolve();
    } else {
      return get("sensors")
        .then(setSensors)
        .then(dispatch)
        .catch(error => {
          if (!error.status) {
            throw error;
          } else if (error.status !== 401) {
            throw new Error(
              `unable to fetch sensors (status: ${error.status})`
            );
          }
        });
    }
  };
}

//
// Nodes
//
/**
 * @param  {array} nodes array of nodes
 * @return {object}        action
 */
export function setNodes(nodes) {
  return { type: actions.NODES_SET, nodes };
}

/**
 * Updates the properties of a given node
 * @param  {object} node properties of the node
 * @return {object}        action
 */
export function updateNode(node) {
  return { type: actions.NODES_UPDATE, node };
}

/**
 * Fetch all nodes
 * @param  {Boolean}  forceRemote forces remote fetch
 * @return {Function} async dispatch function (contacts server)
 */
export function getNodes(forceRemote) {
  return (dispatch, getState) => {
    if (!forceRemote && getState().nodes.size > 0) {
      return Promise.resolve();
    } else {
      return get("nodes")
        .then(setNodes)
        .then(dispatch)
        .catch(error => {
          if (!error.status) {
            throw error;
          } else if (error.status !== 401) {
            throw new Error(`unable to fetch nodes (status: ${error.status})`);
          }
        });
    }
  };
}

/**
 * Updates a node on the server
 * @param   {object}  data the node to be updated with the properties
 *                    of the node to update (merged in)
 * @return {object}   action
 */
export function saveNode(data) {
  return (dispatch, getState) => {
    const original = getState().nodes.get(data.id) || data;
    const dispatchUpdate = node => dispatch(updateNode(node));
    return _saveWithPending(dispatchUpdate, original, data, "nodes", data);
  };
}

//
// Subscriptions
//
/**
 * @param  {array}  subscriptions the array of subscriptions to add
 * @return {object}               action
 */
export function setSubscriptions(subscriptions) {
  return { type: actions.SUBSCRIPTIONS_SET, subscriptions };
}

/**
 * Updates an array of subscriptions in the store
 * @param  {object} subscriptions array of updated subscriptions
 * @return {object}              action
 */
export function updateSubscriptions(subscriptions) {
  return { type: actions.SUBSCRIPTIONS_UPDATE, subscriptions };
}

/**
 * Fetch subscriptions for a given user
 * @param  {string}   userId the id of the site to fetch subscriptions
 * @return {Function}        async fetch function
 */
export function getSubscriptionsForUser(userId) {
  return dispatch => {
    return get(`/users/${userId}/subscriptions`)
      .then(setSubscriptions)
      .then(dispatch)
      .catch(error => {
        if (!(error instanceof HttpError)) {
          throw error;
        } else if (error.status !== 401) {
          throw new Error(
            `unable to fetch subscriptions for user ${userId} (status: ${
              error.status
            })`
          );
        }
      });
  };
}

/**
 * Fetch subscriptions for a given site [async, cached]
 * @param {number} siteId the id of the site to fetch subscriptions.
 * @param {boolean} forceRemote forces a fetch.
 * @return {Function} async fetch function.
 */
export const getSubscriptionsForSite = (siteId, forceRemote = false) => (
  dispatch,
  getState
) => {
  if (!forceRemote && getState().subscriptions.size > 0) return;
  return get(`users/subscriptions?${siteId}`)
    .then(setSubscriptions)
    .then(dispatch)
    .catch(error => {
      if (!(error instanceof HttpError)) {
        throw error;
      } else if (error.status !== 401) {
        throw new Error(
          `unable to fetch subscriptions for ${siteId} (status: ${
            error.status
          })`
        );
      }
    });
};

/**
 * Saves subscriptions for a user
 * @param    {array}  subscriptions an object containing the name of the subscription type and booleans
 *                                   named email/sms
 * @param    {Number}  userId        id of the user
 * @return   {Function}              async save function
 */
export function saveSubscriptionsForUser(subscriptions, userId) {
  return (dispatch, getState) => {
    const setStatuses = (list, status) =>
      list.map(v => partialRight(setStatus, status)(v));
    const setErrors = (list, error) =>
      setStatuses(list, STATUS_ERROR).map(k =>
        partialRight(setError, error)(k)
      );
    const originalData = selectUserSubscriptions(getState(), userId);
    const updatedData = setStatuses(subscriptions, STATUS_PENDING);

    const subsByType = subscriptions.reduce((acc, s) => {
      return Object.assign({}, acc, {
        [s.subscriptionType]: { email: s.email, sms: s.sms }
      });
    }, {});

    dispatch(updateSubscriptions(updatedData));
    return put(`users/${userId}/subscriptions`, {
      body: { subscriptions: subsByType }
    })
      .then(() => {
        const finalSub = setStatuses(updatedData, STATUS_SAVED);
        dispatch(updateSubscriptions(finalSub));
      })
      .catch(error => {
        if (!(error instanceof HttpError)) {
          throw error;
        }

        const finalSub = setErrors(originalData, error.body);
        dispatch(updateSubscriptions(finalSub));
      });
  };
}

//
// Thresholds
//
/**
 * @param  {Map} thresholds the new thresholds map
 * @return {object}         action
 */
export function setThresholds(thresholds) {
  return { type: actions.THRESHOLDS_SET, thresholds };
}

/**
 * Adds an array of thresholds to the store
 * @param  {array}  thresholds the array of thresholds to add
 * @return {object}            action
 */
export function addThresholds(thresholds) {
  return { type: actions.THRESHOLDS_ADD, thresholds };
}

/**
 * Adds a single threshold to the list
 * @param  {object} threshold object literal containing threshold properties
 * @return {object}           action
 */
export function addThreshold(threshold) {
  return addThresholds([threshold]);
}

/**
 * Sets the new threshold state
 * @param  {object}  threshold threshold data
 * @return {object}            action
 */
export function setNewThreshold(threshold) {
  return { type: actions.THRESHOLDS_NEW_SET, threshold };
}

/**
 * Increases the count of thresholds being fetched
 * @return {object}            action
 */
export function startLoadingThreshold() {
  return { type: actions.THRESHOLDS_FETCH_START };
}

/**
 * Decreases the count of thresholds being fetched
 * @return {object}            action
 */
export function finishLoadingThreshold() {
  return { type: actions.THRESHOLDS_FETCH_END };
}

/**
 * Fetch thresholds for a given site [async, cached]
 * @param  {string}   streamId the id of the stream to fetch thresholds
 * @return {Function}          async fetch function
 */
export function getThresholdsForStream(streamId) {
  return (dispatch, getState) => {
    const thresholdsForStream = getState().thresholds.get(streamId, Map());

    if (thresholdsForStream.size > 0) {
      return Promise.resolve();
    }

    dispatch(startLoadingThreshold());
    return get(`thresholds?${qs.stringify({ forStream: streamId })}`)
      .then(addThresholds)
      .then(dispatch)
      .then(() => dispatch(finishLoadingThreshold()))
      .catch(error => {
        dispatch(finishLoadingThreshold());
        if (!(error instanceof HttpError)) {
          throw error;
        } else if (error.status !== 401) {
          throw new Error(
            `unable to fetch thresholds for ${streamId} (status: ${
              error.status
            })`
          );
        }
      });
  };
}

/**
 * Registers a new threshold on the server (and adds it to the state)
 * @param  {object}   data threshold registration data
 * @return {Function}      async dispatch function (saves data on API)
 */
export function registerThreshold(data) {
  return dispatch => {
    const newData = setStatus(data, STATUS_PENDING);

    dispatch(setNewThreshold(newData));

    return put("thresholds", { body: data })
      .then(threshold => {
        const finalThreshold = setStatus(threshold, STATUS_SAVED);

        dispatch(addThreshold(finalThreshold));
        dispatch(setNewThreshold(finalThreshold));
      })
      .catch(error => {
        if (!(error instanceof HttpError)) throw error;

        const errorMessage =
          error.status === 410
            ? JSON.parse(error.body).message
            : error.body || error;

        const finalThreshold = setError(data, errorMessage);

        dispatch(setNewThreshold(finalThreshold));
      });
  };
}

/**
 * Add a new "temporary form" threshold in store to be added to the transform.
 * @param  {object}   threshold threshold registration data
 * @return {Function}           async dispatch function
 */
export function addThresholdForTransform(threshold) {
  return dispatch => {
    return dispatch({ type: actions.TF_THRESHOLDS_ADD, threshold });
  };
}

/**
 * Removes the "temporary form" threshold of a given index from store.
 * @param  {number}   id the client id of the threshold to be removed (__id)
 * @return {Function}    async dispatch function
 */
export function removeThresholdForTransform(id) {
  return dispatch => {
    return dispatch({ type: actions.TF_THRESHOLDS_DEL, id });
  };
}

/**
 * Removes all the "temporary form" thresholds from the store.
 * @param  {object}   threshold threshold registration data
 * @return {Function}           async dispatch function (saves data on API)
 */
export function removeAllThresholds() {
  return dispatch => {
    return dispatch({ type: actions.TF_THRESHOLDS_CLEAR });
  };
}

/**
 * Clear thresholds to be added to the transform from state.
 * @return {Function}           async dispatch function (saves data on API)
 */
export function clearThresholdForTransform() {
  return dispatch => {
    return dispatch({ type: actions.TF_CLEAR });
  };
}

/**
 * Add a new "temporary form" chosenMix in store to be added to the transform.
 * @param  {number}   chosenMix the chosenMix id
 * @return {Function}           async dispatch function
 */
export function setChosenMixForTransform(chosenMix) {
  return dispatch => {
    return dispatch({ type: actions.TF_CHOSEN_MIX_SET, chosenMix });
  };
}

/**
 * Add a new "temporary form" startAt in store to be added to the transform.
 * @param  {number}   startAt   the startAt
 * @return {Function}           async dispatch function
 */
export function setStartAtForTransform(startAt) {
  return dispatch => {
    return dispatch({ type: actions.TF_START_AT_SET, startAt });
  };
}

/**
 * Add a new "temporary form" concrete status in store to be added to the transform.
 * @param  {number}   concreteStatus   the concreteStatus
 * @return {Function}                  async dispatch function
 */
export function setConcreteStatusForTransform(concreteStatus) {
  return dispatch => {
    return dispatch({ type: actions.TF_CONCRETE_STATUS_SET, concreteStatus });
  };
}

/**
 * Reset the temporary form for transform from state.
 * @return {Function}           async dispatch function (saves data on API)
 */
export function resetTemporaryFormForTransform() {
  return dispatch => {
    return dispatch({ type: actions.TF_CLEAR });
  };
}

//
// Transforms
//
/**
 * Sets the transforms for a given stream (replaces previous list)
 * @param  {array}  transforms array of transforms to set
 * @return {object}            action
 */
export function setTransforms(transforms) {
  return { type: actions.TRANSFORMS_SET, transforms };
}

/**
 * Fetch a transform
 * @param {integer}   transformId the id of the transform
 * @return {Function}             async fetch function
 */
export const getTransform = transformId => (dispatch, getState) => {
  const transform = getState()
    .transforms.valueSeq()
    .flatten()
    .find(transform => transform.id === transformId);

  if (transform) {
    return Promise.resolve(transform);
  }

  return get(`transforms/${transformId}`)
    .then(addTransform)
    .then(dispatch);
};

/**
 * Fetch all transforms
 * @return {Function} async fetch function
 */
export function getTransforms() {
  return (dispatch, getState) => {
    if (getState().transforms.size > 0) return Promise.resolve();

    return get("transforms")
      .then(addTransforms)
      .then(dispatch)
      .catch(error => {
        if (!(error instanceof HttpError)) {
          throw error;
        } else if (error.status !== 401)
          throw new Error(
            `unable to fetch transforms (status: ${error.status})`
          );
      });
  };
}

/**
 * Fetch transforms for a given stream [async, cached]
 * @param  {string}   streamId the id of the stream for which to fetch transforms
 * @return {Function}          async fetch function
 */
export function getTransformsForStream(streamId) {
  return (dispatch, getState) => {
    const transformsForStream = getState().transforms.get(streamId, Map());

    if (transformsForStream.size > 0) {
      return Promise.resolve();
    }

    return get(`transforms?${qs.stringify({ forStream: streamId })}`)
      .then(addTransforms)
      .then(dispatch)
      .catch(error => {
        if (!(error instanceof HttpError)) {
          throw error;
        } else if (error.status !== 401) {
          throw new Error(
            `unable to fetch transforms for ${streamId} (status: ${
              error.status
            })`
          );
        }
      });
  };
}

/**
 * Adds an array of transforms to the store
 * @param  {array}  transforms the array of transforms to add
 * @return {object}          action
 */
export function addTransforms(transforms) {
  return { type: actions.TRANSFORMS_ADD, transforms };
}

/**
 * Adds a single transform to the store
 * @param  {object} transform properties of the transform
 * @return {object}         action
 */
export function addTransform(transform) {
  return addTransforms([transform]);
}

/**
 * Updates a single transform in the store
 * @param  {object} transform properties of the transform
 * @return {object}         action
 */
export function updateTransform(transform) {
  return { type: actions.TRANSFORMS_UPDATE, transform };
}

/**
 * Starts a pending transform
 * @param  {integer} transformId transform ID
 * @param  {Date}    startAt     optional start date (defaults to now)
 * @return {Function}            async dispatch function
 */
export const startTransform = (transformId, startAt) => (
  dispatch,
  getState
) => {
  return put(`transforms/${transformId}/start`, { body: { startAt } })
    .then(transform => {
      const finalTransform = setStatus(transform, STATUS_SAVED);

      dispatch(updateTransform(finalTransform));
    })
    .catch(error => {
      if (!(error instanceof HttpError)) {
        throw error;
      }
      const transform = getState()
        .transforms.flatten()
        .get(transformId);

      dispatch(updateTransform(setError(transform, error.body)));
    });
};

/**
 * Registers a new transform on the server (and adds it to the state)
 * @param  {string}   streamId            the id of the stream for which to fetch transforms
 * @param  {Date}     startAt             start date
 * @param  {object}   concreteMixDesignId the id of the mix design for the transform
 * @param  {array}    thresholds          the thresholds array
 * @return {Function}                     async dispatch function (saves data on API)
 */
export function registerTransform(
  streamId,
  startAt,
  concreteMixDesignId,
  thresholds
) {
  const sendData = { streamId, startAt, concreteMixDesignId, thresholds };
  const transformData = Object.assign({}, sendData, {
    func: "concrete_maturity"
  });
  return (dispatch, getState) => {
    const newData = setStatus(transformData, STATUS_PENDING);

    dispatch(setNewTransform(newData));

    return put("transforms", { body: sendData })
      .then(transform => {
        const finalTransform = setStatus(transform, STATUS_SAVED);
        const finalStream = setStatus(transform.outputStream, STATUS_SAVED);
        Object.assign(finalStream, {
          metric: selectMetric(getState(), finalStream.metricId)
        });

        delete finalTransform.outputStream;
        dispatch(setNewTransform(finalTransform));
        dispatch(addTransform(finalTransform));
        dispatch(addStream(finalStream));
        if (transform.thresholds) {
          dispatch(addThresholds(transform.thresholds));
        }

        return transform;
      })
      .catch(error => {
        if (!(error instanceof HttpError)) {
          throw error;
        }

        const finalTransform = setError(transformData, error.body);
        dispatch(setNewTransform(finalTransform));
      });
  };
}

/**
 * Reconfigures a transform on the server (and adds it to the state)
 * @param  {string}   transformId         the id of the transform that should be reconfigured
 * @param  {Date}     startAt             start date
 * @param  {object}   concreteMixDesignId the id of the mix design for the transform
 * @param  {array}    thresholds          the thresholds array
 * @return {Function}                     async dispatch function (saves data on API)
 */
export function reconfigureTransform(
  transformId,
  startAt,
  concreteMixDesignId
) {
  const sendData = { id: transformId, startAt, concreteMixDesignId };

  return (dispatch, getState) => {
    const updatedData = setStatus(sendData, STATUS_PENDING);

    const originalTransform = selectTransform(getState(), transformId);

    dispatch(updateTransform(updatedData));

    return post(`transforms/${transformId}/reconfigure`, {
      body: pick(sendData, ["startAt", "concreteMixDesignId"]),
      noSiteId: true
    })
      .then(transform => {
        const finalTransform = setStatus(transform, STATUS_SAVED);
        const finalStream = setStatus(transform.outputStream, STATUS_SAVED);
        Object.assign(finalStream, {
          metric: selectMetric(getState(), finalStream.metricId)
        });

        delete finalTransform.outputStream;

        dispatch(updateTransform(finalTransform));

        return transform;
      })
      .catch(error => {
        if (!(error instanceof HttpError)) {
          throw error;
        }

        const finalTransform = setError(originalTransform, error.body);
        dispatch(updateTransform(finalTransform));
      });
  };
}

/**
 * Sets the new transform state
 * @param  {object}  transform transform data
 * @return {object}            action
 */
export function setNewTransform(transform) {
  return { type: actions.TRANSFORMS_NEW_SET, transform };
}

// Concrete mix designs

/**
 * Sets the concrete mix designs (clears the previous list)
 *
 * @param {array} mixes an array of the mix designs to add
 * @returns {object} action
 */
export function setConcreteMixDesigns(mixes) {
  return { type: actions.CONCRETE_MIX_DESIGNS_SET, mixes };
}

/**
 * Fetch all concrete mix designs
 *
 * @param {Boolean} forceRemote forces remote fetch
 * @returns {Function} thunk action
 */
export const getConcreteMixDesigns = forceRemote => (dispatch, getState) => {
  if (!forceRemote && getState().concrete_mix_designs.size > 0) {
    return Promise.resolve();
  } else {
    return get("concrete_mix_designs")
      .then(setConcreteMixDesigns)
      .then(dispatch)
      .catch(error => {
        if (error.status !== 401)
          throw new Error(
            `unable to fetch concrete mix designs (status: ${error.status})`
          );
      });
  }
};

// Metrics

/**
 * Sets the metrics list (clears any previous list)
 * @param  {array}  metrics an array of metrics to add
 * @return {object}         action
 */
export function setMetrics(metrics) {
  return { type: actions.METRICS_SET, metrics };
}

/**
 * Fetch all metrics
 * @param  {Boolean}  forceRemote forces remote fetch
 * @return {Function}             async dispatch function (contacts server)
 */
export function getMetrics(forceRemote) {
  return (dispatch, getState) => {
    if (!forceRemote && getState().metrics.size > 0) {
      return Promise.resolve();
    } else {
      return get("metrics")
        .then(metrics => {
          return dispatch(setMetrics(metrics));
        })
        .catch(error => {
          if (error.status !== 401)
            throw new Error(
              `unable to fetch metrics (status: ${error.status})`
            );
        });
    }
  };
}

/**
 * Starts a session for the current user on fullStory if there's a user logged in
 * @return {Function}             async dispatch function
 */
export function startFullstorySession() {
  return (dispatch, getState) => {
    const { auth } = getState();
    const isLoggedIn = auth.get("__status") === STATUS_SAVED;
    const user = auth.get("user");

    const isTrackableUser =
      user && !FS_IGNORED_ORGANISATION_IDS.includes(user.get("organisationId"));
    if (isLoggedIn && isTrackableUser) {
      global._includeFullStory();
      global.FS.identify(user.get("id"), {
        displayName: user.get("name"),
        email: user.get("email")
      });
    }

    return Promise.resolve();
  };
}

/**
 * Ends a session on fullStory for the current user
 * @return {Function}             async dispatch function
 */
export function endFullstorySession() {
  return () => {
    if (global.FS) {
      global.FS.identify(false);
    }
    return Promise.resolve();
  };
}

// Reset password

/**
 * Update the reset password state
 * @param  {object} resetPassword new state
 * @return {object}               action
 */
export function resetPasswordUpdate(resetPassword) {
  return { type: actions.RESET_PASSWORD_UPDATE, resetPassword };
}

/**
 * Request a password reset email
 * @param  {string} email   email address
 * @return {Function}       thunk action
 */
export const requestPasswordResetAttempt = email => dispatch => {
  dispatch(resetPasswordUpdate(setStatus({}, STATUS_IN_PROGRESS)));
  return put("password_reset_tokens", { body: { email } })
    .then(() => setStatus({}, STATUS_PENDING))
    .catch(error => setError({}, JSON.parse(error.body).error))
    .then(resetPasswordUpdate)
    .then(dispatch);
};

/**
 * Attempt to use a password reset token
 * @param  {string} token                password reset token
 * @param  {string} password             new password
 * @param  {string} passwordConfirmation confirmation of the new password
 * @return {Function}                    action thunk
 */
export const resetPassword = (
  token,
  password,
  passwordConfirmation
) => dispatch => {
  dispatch(resetPasswordUpdate(setStatus({}, STATUS_IN_PROGRESS)));
  return put(`password_reset_tokens/${token}/use`, {
    body: { password, passwordConfirmation }
  })
    .then(() => dispatch(resetPasswordUpdate(setStatus({}, STATUS_SAVED))))
    .then(() => dispatch(authUpdate(setStatus({}, STATUS_PENDING))))
    .catch(error => {
      const body = JSON.parse(error.body);
      error = body.error || body.errors.password[0] || "Unknown error";
      dispatch(resetPasswordUpdate(setError({}, error)));
      dispatch(setMessage("error", null, error));
    });
};

// Users
/**
 * Set the list of known users
 * @param  {array} users  new list of users
 * @return {object}       action
 */
export function setUsers(users) {
  return { type: actions.USERS_SET, users };
}

/**
 * Fetch the list of known users for a site
 * @param {string} siteId the site.
 * @param {boolean} forceRemote forces a fetch.
 * @return {Function} for redux-thunk.
 */
export const getUsers = (siteId, forceRemote = false) => (
  dispatch,
  getState
) => {
  if (forceRemote || getState().users.size === 0) {
    return get(`/permissions/${siteId}/users`)
      .then(setUsers)
      .then(dispatch)
      .catch(error => {
        if (error.status !== 401) {
          throw new Error(`unable to fetch users (status: ${error.status})`);
        }
      });
  }
};

/**
 * Turns on a user's isBeta flag.
 * @return {Function} for redux-thunk
 */
export const activateBeta = () => (dispatch, getState) => {
  const { auth } = getState();
  const authToken = auth.get("authToken");

  return post("/users/activate_beta")
    .then(user => {
      dispatch(authUpdate(setStatus({ user, authToken }, STATUS_SAVED)));
      dispatch(setUsers([user]));
    })
    .catch(error => {
      if (error.status !== 401) throw new Error("unable to activate beta");
    });
};

/**
 * Accepts the ToS
 * @return {Function}  for redux-thunk
 */
export const acceptTos = () => (dispatch, getState) => {
  const { auth } = getState();
  const authToken = auth.get("authToken");
  return post("/users/accept_tos")
    .then(user => {
      dispatch(authUpdate(setStatus({ user, authToken }, STATUS_SAVED)));
    })
    .catch(error => {
      if (error.status !== 401) throw new Error("unable to acceptTos");
    });
};

/**
 * Accepts the cookie policy
 * @return {Function}  for redux-thunk
 */
export const acceptCookiePolicy = () => (dispatch, getState) => {
  const { auth } = getState();
  const authToken = auth.get("authToken");
  return post("/users/accept_cookie_policy")
    .then(user => {
      dispatch(authUpdate(setStatus({ user, authToken }, STATUS_SAVED)));
    })
    .catch(error => {
      if (error.status !== 401) throw new Error("unable to cookie policy");
    });
};

/**
 * Set the list of sites
 * @param  {array} sites list of sites
 * @return {object}              action
 */
export const setSites = sites => {
  const sortedSites = sites
    .slice()
    .sort((a, b) => (a.name > b.name) - (a.name < b.name));
  return {
    type: actions.SITES_SET,
    sites: sortedSites
  };
};

/**
 * Fetch the list of existing sites from the API, unless it's already been fetched
 * @return {Function}   for redux-thunk
 */
export const getSites = () => dispatch => {
  return get("sites")
    .then(setSites)
    .then(dispatch)
    .catch(error => {
      if (error.status !== 401)
        throw new Error(`unable to fetch sites (status: ${error.status})`);
    });
};

/**
 * Set the site to the one specified - and refresh
 * @param  {string} siteId the site to change to
 * @return {Function}   for redux-thunk
 */
export const changeSite = siteId => (dispatch, getState) => {
  const site = getState().sites.get(siteId);
  if (!site) throw new TypeError(`Invalid site id ${siteId}`);

  dispatch(updateSiteSetting(site));
  [
    setSensors,
    setStreams,
    setConcreteMixDesigns,
    setTransforms,
    setThresholds,
    setUsers,
    setNodes
  ]
    .map(creator => creator([]))
    .forEach(dispatch);

  dispatch(resetAllAlerts());

  return site;
};

// Messages

/**
 * Adds a global message.
 * @param  {String} messageType The message type.
 * @param  {String} title The title.
 * @param  {String} message The message.
 * @return {object}         action
 */
export function setMessage(messageType, title, message) {
  return { type: actions.ADD_MESSAGE, messageType, title, message };
}

/**
 * Creates the `CLEAR_MESSAGES` action.
 * @return {object}            action
 */
export function clearMessages() {
  return { type: actions.CLEAR_MESSAGES };
}

export const resetMessages = () => dispatch => {
  return Promise.resolve()
    .then(() => clearMessages())
    .then(dispatch);
};

/**
 * @param  {array} pour   a single pour
 * @return {object}       action
 */
export function setPour(pour) {
  return { type: actions.POURS_SET, pours: [pour] };
}

/**
 * @param  {array} pours  array of pours
 * @return {object}       action
 */
export function setPours(pours) {
  return { type: actions.POURS_SET, pours };
}

/**
 * @param  {array} pour   a single pour
 * @return {object}       action
 */
export function addPour(pour) {
  return { type: actions.POURS_ADD, pours: [pour] };
}

/**
 * @param  {array} pour   a single pour
 * @return {object}       action
 */
export function deletePour(pour) {
  return { type: actions.POURS_DELETE, pours: [pour] };
}

/**
 * Creates a new pour
 * @param  {object}   pour the pour object to save
 * @return {Function} async dispatch function (contacts server)
 */
export function createPour(pour) {
  return async (dispatch, getState) => {
    const currentSiteId = getState().settings.getIn(["site", "id"]);
    const reqBody = { siteId: currentSiteId, ...pour };

    try {
      const savedPour = await put("pours", { body: reqBody });
      const action = addPour(Object.assign({ sensorCount: 0 }, savedPour));
      dispatch(action);
      return savedPour;
    } catch (error) {
      if (!error.status) {
        throw error;
      } else if (error.status !== 401) {
        throw new Error(`unable to save pour (status: ${error.status})`);
      }
    }
  };
}

/**
 * Fetch all pours
 * @param  {Boolean}  forceRemote forces remote fetch
 * @return {Function} async dispatch function (contacts server)
 */
export function getPours(forceRemote) {
  return async (dispatch, getState) => {
    if (!forceRemote && getState().pours.size > 0) {
      return;
    }
    try {
      const pours = await get("pours");
      const action = setPours(pours);
      return dispatch(action);
    } catch (error) {
      if (!error.status) {
        throw error;
      } else if (error.status !== 401) {
        throw new Error(`unable to fetch pours (status: ${error.status})`);
      }
    }
  };
}

/**
 * Edit a pour.
 * @param  {pour}     pour the pour being edited
 * @return {Function} async dispatch function (contacts server)
 */
export function editPour(pour) {
  return async (dispatch, getState) => {
    const currentSiteId = getState().settings.getIn(["site", "id"]);
    const reqBody = { siteId: currentSiteId, ...pour };

    try {
      await post("pours", { body: reqBody });
      const action = setPour(pour);
      return dispatch(action);
    } catch (error) {
      if (!error.status) {
        throw error;
      } else if (error.status !== 401) {
        throw new Error(`unable to update pour (status: ${error.status})`);
      }
    }
  };
}

/**
 * Removes a pour.
 * @param  {object}   pour the pour object to delete
 * @return {Function} async dispatch function (contacts server)
 */
export function removePour(pour) {
  return async (dispatch, getState) => {
    const currentSiteId = getState().settings.getIn(["site", "id"]);

    try {
      await del(`pours/${pour.id}?siteId=${currentSiteId}`);
      const action = deletePour(pour);
      return dispatch(action);
    } catch (error) {
      if (!error.status) {
        throw error;
      } else if (error.status !== 401) {
        throw new Error(`unable to delete pour (status: ${error.status})`);
      }
    }
  };
}

//
// API auth_tokens
//
/**
 * @param  {object} token  the API token
 * @return {object}        action
 */
export function updateToken(token) {
  return { type: actions.API_TOKEN_UPDATE, token };
}

/**
 * Fetch current API token for a user
 * @return {Function}        for redux-thunk
 */
export function getCurrentToken() {
  return async dispatch => {
    try {
      const response = await get("/api_tokens/current");
      const action = updateToken(response);
      return dispatch(action);
    } catch (error) {
      if (!error.status) {
        throw error;
      } else if (error.status !== 401) {
        throw new Error(`unable to fetch your token (status: ${error.status})`);
      }
    }
  };
}

/**
 * Create a new API token for a user
 * @param  {number}   siteId the id of the site the user belongs to
 * @return {Function}        for redux-thunk
 */
export function createToken(siteId) {
  return async dispatch => {
    try {
      const response = await put(`/api_tokens?siteId=${siteId}`);
      const action = updateToken(response);
      return dispatch(action);
    } catch (error) {
      if (!error.status) {
        throw error;
      } else if (error.status !== 401) {
        throw new Error(
          `unable to generate your token (status: ${error.status})`
        );
      }
    }
  };
}

//
// Predictions
//
/**
 * @param  {object}  prediction   the prediction to add
 * @param  {string}  sensorId     the id of the sensor for which prediction belongs to
 * @param  {number}  milestone    the value of the milestone for which the prediction belongs to
 * @return {object}               action
 */
export function setPrediction(prediction, sensorId, milestone) {
  return {
    type: actions.PREDICTIONS_SET,
    prediction,
    sensorId,
    milestone
  };
}

/**
 * Fetch prediction for a given sensor's milestone
 * @param  {string}   sensorId  the id of the sensor for which to fetch the prediction
 * @param  {number}   milestone the value of the milestone for which to fetch the prediction
 * @return {Function}           async fetch function
 */
export function getPredictionForSensorMilestone(sensorId, milestone) {
  return async dispatch => {
    try {
      const response = await get(
        `/delphi/prediction?sensorId=${sensorId}&milestone=${milestone}`
      );
      const prediction = pick(response, [
        "expectedTimeWindowStart",
        "expectedTimeWindowEnd",
        "error"
      ]);
      const action = setPrediction(prediction, sensorId, milestone);
      return dispatch(action);
    } catch (error) {
      if (!error.status) {
        throw error;
      } else if (error.status !== 401) {
        log(
          { error },
          `unable to fetch the prediction for sensor ${sensorId} (status: ${
            error.status
          })`
        );
        const prediction = {
          error: "NotAvailableError"
        };
        const action = setPrediction(prediction, sensorId, milestone);
        return dispatch(action);
      }
    }
  };
}

//
// Alerts
//
/**
 * @param  {string}  alertId      the alertId to dismiss
 * @return {object}               action
 */
export function dismissAlertById(alertId) {
  return { type: actions.ALERTS_DISMISS, alertId };
}

/**
 * @param  {string}  alertId      the alertId to dismiss
 * @return {object}               action
 */
export function resetAllAlerts() {
  return { type: actions.ALERTS_RESET };
}

/**
 * Dismisses an alert by its ID.
 * @param  {string}  alertId    the alertId to dismiss
 * @return {Function}           dismiss function
 */
export function dismissAlert(alertId) {
  return dispatch => dispatch(dismissAlertById(alertId));
}

/**
 * Resets all alerts to their initial availability state.
 *
 * @return {Function}           dismiss function
 */
export function resetAlerts() {
  return dispatch => dispatch(resetAllAlerts());
}

//
// Organisation for site
//
/**
 * @param  {object} organisationForSite  the organisation for a site
 * @return {object}                      action
 */
export function setOrganisationForSite(organisationForSite) {
  return { type: actions.ORGANISATIONS_SITES_SET, organisationForSite };
}

/**
 * Fetch an organisation for which a site belongs to
 * @param  {string}   siteId the id of the site for which to fetch the organisation for
 * @return {function}        async fetch function
 */
export function getOrganisationForSite(siteId) {
  return async dispatch => {
    try {
      const response = await get(`/permissions/${siteId}/organisations`);
      const action = setOrganisationForSite(response);
      return dispatch(action);
    } catch (error) {
      if (!error.status) {
        throw error;
      } else if (error.status !== 401) {
        throw new Error(
          `unable to fetch organisation (status: ${error.status})`
        );
      }
    }
  };
}
