"use strict";

import React from "react";
import PropTypes from "prop-types";
import Message from "../message";

// TODO this should wrap login_view by default
// This should not be a separate file just for a wrapper
export const LoginViewWrapper = ({ children }) => {
  return (
    <div className="LoginLayout">
      {children}
      <Message />
    </div>
  );
};

LoginViewWrapper.displayName = "LoginViewWrapper";

LoginViewWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default LoginViewWrapper;
