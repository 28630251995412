"use strict";

import { fromJS } from "immutable";
import { SETTINGS_UPDATE_DURATION, SETTINGS_UPDATE_SITE } from "../actions";

export default function reducer(
  state = fromJS({ duration: "all time" }),
  action
) {
  if (action.type === SETTINGS_UPDATE_DURATION)
    return state.set("duration", action.duration);
  if (action.type === SETTINGS_UPDATE_SITE)
    return state.set("site", fromJS(action.site));

  return state;
}
