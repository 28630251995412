"use strict";

import { Map } from "immutable";
import { ADD_MESSAGE, CLEAR_MESSAGES } from "../actions";

export default function reducer(
  state = new Map(),
  { type, messageType, title, message }
) {
  if (type === ADD_MESSAGE) {
    return state.merge({ messageType, title, message });
  }

  if (type === CLEAR_MESSAGES) {
    return new Map();
  }

  return state;
}
