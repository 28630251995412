"use strict";

import { bsearchList } from "./bsearch";
import * as sortedTools from "./sorted_list";
import { List } from "immutable";

/**
 * Insert an item into a List in an ordered manner
 * @param  {Immutable.List} list       the list into which to insert
 * @param  {mixed}          item       the item to insert
 * @param  {Function}       comparator function to compare values
 * @return {Immutable.List}            new list object containing new item
 */
export function insert(list, item, comparator) {
  const i = bsearchList(list, item, comparator);
  return list.splice(i, 0, item);
}

/**
 * Inserts many items into a List in an ordered manner
 * @param  {Immutable.List} list       the list into which to insert
 * @param  {array}          items      the items to insert
 * @param  {Function}       comparator function to compare values
 * @return {Immutable.List}            new list object containing new items
 */
export function insertMany(list, items, comparator) {
  // because splice() is not mutative in Immutable Collections
  // we are transforming to an array first, performing operation
  // and then converting back to a list

  const arr = list.toArray();
  sortedTools.insert(arr, items, comparator);

  return List(arr);
}

/**
 * Finds the index of an item in a List, or returns null if it is not found
 * @param  {Immutable.List} list       the list to search within
 * @param  {mixed}          item       the item for which to search
 * @param  {Function}       comparator function to compare values
 * @return {integer|null}              the index of the item or null if not found
 */
export function findIndex(list, item, comparator) {
  const i = bsearchList(list, item, comparator);

  if (comparator(list.get(i), item) === 0) {
    return i;
  } else {
    return null;
  }
}

/**
 * Delete an item from a List in an ordered manner
 * @param  {Immutable.List} list       the List from which to delete
 * @param  {mixed}          item       the item to delete
 * @param  {Function}       comparator function to compare values
 * @return {Immutable.List}            new list object without the item specified for deletion
 */
export function del(list, item, comparator) {
  const i = findIndex(list, item, comparator);

  return i === null ? list : list.delete(i);
}
