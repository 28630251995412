import React from "react";

const BlinkingRedDot = () => {
  return (
    <div className="BlinkingRedDot_wrapper">
      <div className="BlinkingRedDot_dot" />
    </div>
  );
};

export default BlinkingRedDot;
