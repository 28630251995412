"use strict";

import { Map } from "immutable";
import { NODES_UPDATE, NODES_SET } from "../actions";

const setter = (prev, node) => prev.set(node.id, node);

export default function reducer(state = new Map(), { type, node, nodes }) {
  switch (type) {
    case NODES_SET:
      return nodes.reduce(setter, new Map());
    case NODES_UPDATE:
      return state.mergeIn(
        [node.id],
        Object.assign({}, state.get(node.id), node)
      );
    default:
      return state;
  }
}
