"use strict";

import moment from "moment";

/**
 * Creates an inverse-comparator (returns the opposite of the original comparator)
 * @param  {Function} comparator the function to invert
 * @return {Function}            inverted comparator function
 */
export function inverse(comparator) {
  return (a, b) => comparator(b, a);
}

/**
 * Creates a comparator which operates on the given property of input objects, using the
 * given comparator function
 * @param  {Function} comparator the comparator function to wrap
 * @param  {string}   property   property to compare
 * @return {Function}            wrapped comparator function
 */
export function onProperty(comparator, property) {
  return (a, b) => comparator(a[property], b[property]);
}

/**
 * Basic comparator using infix operator "<"
 * @param  {mixed}  a first value to compare
 * @param  {mixed}  b second value to compare
 * @return {number}   -1 if a<b, otherwise +1
 */
export function basic(a, b) {
  if (a === b) {
    return 0;
  }

  return a < b ? -1 : +1;
}

/**
 * Compares on the basis of string length
 * @param  {string} a first value to compare
 * @param  {string} b second value to compare
 * @return {number}   -1 if a.length < b.length, otherwise +1
 */
export const stringLength = onProperty(basic, "length");

/**
 * Compares strings in alphabetical order
 * @param  {string} a first value to compare
 * @param  {string} b second value to compare
 * @return {number}   -1 if a comes before b, otherwise +1
 */
export function alphabetical(a, b) {
  return basic(a, b); // standard infix operators work alphabetically with strings
}

/**
 * Compares dates putting the earlier date first
 * @param  {string|Date|Moment} a first value to compare
 * @param  {string|Date|Moment} b second value to compare
 * @return {number}               -1 is a is before b, otherwise +1
 */
export function dateEarliestFirst(a, b) {
  const diff = moment(a).diff(b);

  if (diff === 0) {
    return 0;
  } else {
    return diff < 0 ? -1 : +1;
  }
}

/**
 * Compares dates putting the later date first
 * @param  {string|Date|Moment} a first value to compare
 * @param  {string|Date|Moment} b second value to compare
 * @return {number}               -1 is a is after b, otherwise +1
 */
export const dateLatestFirst = inverse(dateEarliestFirst);
