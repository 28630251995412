"use strict";

import { Map } from "immutable";
import { SUBSCRIPTIONS_SET, SUBSCRIPTIONS_UPDATE } from "../actions";

const set = (o, k, v) => Object.assign({}, o, { [k]: v });
const indexById = arr => arr.reduce((acc, curr) => set(acc, curr.id, curr), {});

export default function reducer(state = new Map(), { type, subscriptions }) {
  switch (type) {
    case SUBSCRIPTIONS_SET:
      return Map(indexById(subscriptions));
    case SUBSCRIPTIONS_UPDATE:
      return state.merge(Map(indexById(subscriptions)));
    default:
      return state;
  }
}
