"use strict";

import { Map } from "immutable";
import { MIX_DESIGN_PERFORMANCE_SET } from "../actions";

const setter = (prev, mixDesignPerformance) =>
  prev.set(mixDesignPerformance.title, mixDesignPerformance);

export default function reducer(
  state = new Map(),
  { type, mixDesignPerformance }
) {
  if (type === MIX_DESIGN_PERFORMANCE_SET) {
    return mixDesignPerformance.reduce(setter, new Map());
  }
  return state;
}
