import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import NavItem from "../nav_item";

const NavActionItem = ({ onClick, children, className }) => {
  return (
    <NavItem>
      <a className={classnames("NavActionItem", className)} onClick={onClick}>
        {children}
      </a>
    </NavItem>
  );
};

NavActionItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default NavActionItem;
