"use strict";

import React, { PureComponent } from "react";
import { render } from "react-dom";
import {
  Router,
  Route,
  IndexRedirect,
  hashHistory,
  Redirect
} from "react-router";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { STATUS_SAVED } from "./store/action_creators";

import Loadable from "./components/loadable_component";

// Sync Component views
import AppComponent from "./components/app";
import LoginLayout from "./components/white/login";
import LayoutWithSubnavbar from "./components/white/layouts/subnavbar_layout";
import LayoutWithBlueBackground from "./components/white/layouts/blue_background_layout";
import PoliciesLayout from "./components/white/layouts/policies_layout";
import { FeatureFlag } from "./components/feature_flag";
import { not, rules, check } from "./feature_flag_rules";
// Async Component views
const AsyncLoginView = Loadable(() =>
  import("./components/white/login/login_view")
);
const AsyncResetPasswordView = Loadable(() => {
  return import("./components/white/login/reset_password_view");
});

const AsyncSensorRegistrationView = Loadable(() => {
  return import("./components/white/sensor_registration_view");
});

const AsyncPoliciesView = Loadable(() =>
  import("./components/white/policies_view")
);

const AsyncPourViewComponent = Loadable(() =>
  import("./components/white/pour_view")
);

const AsyncSensorDetailComponent = Loadable(() =>
  import("./components/white/sensor_detail")
);

const AsyncNotificationPreferencesView = Loadable(() => {
  return import("./components/white/notification_preferences_view");
});

const AsyncTokenGenerator = Loadable(() => {
  return import("./components/white/token_generator_view");
});

// White Version
const AsyncWhiteInventoryIndexView = Loadable(() => {
  return import("./components/white/inventory_index_view");
});
const AsyncSensorsIndexView = Loadable(() =>
  import("./components/white/sensors_index_view")
);
const AsyncTemperatureDifferentialsView = Loadable(() =>
  import("./components/white/temperature_differential")
);
const AsyncPoursIndexView = Loadable(() =>
  import("./components/white/pours_index_view")
);
const AsyncKpiDashboardView = Loadable(() =>
  import("./components/white/kpi_dashboard")
);

const featureFlagRedirect = (ruleset, url) => (_, replace) => {
  const { auth, settings } = store.getState();
  const user = auth.get("user").toJSON();
  const site = settings.get("site").toJSON();

  if (!check(ruleset, user, site)) {
    replace({ pathname: url });
    return true;
  }
};

const redirectWithReferrer = (checkFn, url) => (nextState, replace) => {
  if (checkFn()) {
    replace({
      pathname: url,
      query: { from: nextState.location.pathname + nextState.location.search }
    });

    return true;
  }
};

const notAuthenticated = () => {
  const { auth } = store.getState();
  const tokenId = auth.getIn(["authToken", "id"]);
  const status = auth.get("__status");
  const loggedIn =
    typeof tokenId === "string" &&
    tokenId.length > 0 &&
    status === STATUS_SAVED;
  if (!loggedIn) {
    return true;
  }
};

const tosNotSigned = () => {
  const { auth } = store.getState();
  const signedTos = auth.getIn(["user", "tosSignedAt"]);
  if (!signedTos) {
    return true;
  }
};

const requireConcretePerformanceAccess = featureFlagRedirect(
  rules.concretePerformance,
  "/"
);
const requireApiTokenAccess = featureFlagRedirect(rules.apiToken, "/");
const requireAuth = redirectWithReferrer(notAuthenticated, "/login");
const requireToS = redirectWithReferrer(tosNotSigned, "/policies");

const requireAuthAndTos = (...args) => {
  const authRedirected = requireAuth.apply(null, args);
  if (!authRedirected) requireToS.apply(null, args);
};

export class Wrapper extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Provider store={store}>
        <Router history={hashHistory}>
          <Route path="/" name="app" component={AppComponent}>
            <Route component={LoginLayout}>
              <Route
                path="/reset_password/:token"
                component={AsyncResetPasswordView}
              />
              <Route path="login" component={AsyncLoginView} />
            </Route>
            <Route onEnter={requireAuth} component={PoliciesLayout}>
              <Route path="/policies" component={AsyncPoliciesView} />
            </Route>
            <Route onEnter={requireAuthAndTos}>
              <Route component={LayoutWithSubnavbar}>
                <FeatureFlag rules={not(rules.pours)}>
                  <IndexRedirect to="/sensors" />
                  <Redirect from="/streams" to="/sensors" />
                </FeatureFlag>
                <FeatureFlag rules={rules.pours}>
                  <IndexRedirect to="/pours" />
                  <Redirect from="/streams" to="/pours" />
                </FeatureFlag>

                <Route path="/pours" component={AsyncPoursIndexView} />
                <Route
                  path="/pour/:pourId"
                  component={AsyncPourViewComponent}
                />
                <Route path="/sensors" component={AsyncSensorsIndexView} />
                <Route
                  path="/inventory"
                  component={AsyncWhiteInventoryIndexView}
                />
                <Route
                  path="/register"
                  component={AsyncSensorRegistrationView}
                />
                <Route path="/scan" component={AsyncSensorRegistrationView} />
                <Route
                  path="/view/:sensorId"
                  component={AsyncSensorDetailComponent}
                />
                <Redirect from="/view/:nodeId/:sensorId" to="/view/:sensorId" />
                <Route
                  path="/users/settings"
                  component={AsyncNotificationPreferencesView}
                />
                <Route
                  path="/differentials"
                  component={AsyncTemperatureDifferentialsView}
                />
                <Route
                  path="/token_generator"
                  component={AsyncTokenGenerator}
                  onEnter={requireApiTokenAccess}
                />
              </Route>
              <Route component={LayoutWithBlueBackground}>
                <Route
                  path="/concrete_performance"
                  component={AsyncKpiDashboardView}
                  onEnter={requireConcretePerformanceAccess}
                />
              </Route>
              <Route component={LayoutWithBlueBackground}>
                <FeatureFlag rules={rules.concretePerformance}>
                  <Route
                    path="/concrete_performance"
                    component={AsyncKpiDashboardView}
                  />
                </FeatureFlag>
              </Route>
            </Route>
          </Route>
        </Router>
      </Provider>
    );
  }
}

export default function routes(container) {
  render(<Wrapper />, container);
}
