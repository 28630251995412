import React from "react";
import classnames from "classnames";

import NavItem from "../nav_item";
import NavDropdown from "../nav_dropdown";
import NavLink from "../nav_link";

export const SupportDropdown = () => {
  return (
    <NavDropdown label="Customer Support & Help" className="InlineDropdown">
      <NavLink
        url="https://help.converge.io/"
        target="_blank"
        className="NavLink_dropdownItem"
      >
        Online Help Guide
      </NavLink>
      <NavLink url="tel:+442038083115" className="NavLink_dropdownItem">
        0203 808 3115
      </NavLink>
      <NavLink url="mailto:help@converge.io" className="NavLink_dropdownItem">
        help@converge.io
      </NavLink>
      <NavItem
        classOverrides={classnames(
          "NavLink_dropdownItem",
          "intercomCustomLauncher"
        )}
      >
        Chat now on Intercom
      </NavItem>
    </NavDropdown>
  );
};

export default SupportDropdown;
