import withSizes from "react-sizes";

export const isApp = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  const isIosWebview = /iphone|ipod|ipad/.test(agent) && !/safari/.test(agent);
  const isAndroidWebview = /android/.test(agent) && /wv\)/.test(agent);

  return isIosWebview || isAndroidWebview;
};

export const withDevices = withSizes(({ width }) => {
  return { isMobile: width < 768, isApp: isApp() };
});
