"use strict";

import fetch from "isomorphic-fetch";
import debug from "debug";
import { store, dispatch } from "./store/store";
import { STATUS_PENDING, authUpdate } from "./store/action_creators";

const BASE_URL = process.env.API_ENDPOINT || "https://localhost:8080";

const log = debug("store");

/**
 * Takes a relative path and returns a fully qualified URL
 * @param  {string} path the relative path on the server
 * @param  {string} siteId the siteId to be added to the request (or unknown)
 * @return {string}      fully qualified URL
 */
export function makeFullUrl(path, siteId) {
  if (path[0] !== "/") {
    path = "/" + path;
  }

  if (siteId) {
    const delimiter = path.includes("?") ? "&" : "?";
    path = `${path}${delimiter}siteId=${siteId}`;
  }
  return BASE_URL + path;
}

/**
 * Normalise the request options, add siteId to body if it's not a string
 * and this isn't a get request
 * @param  {object} opts unnormalised options object
 * @param  {string} siteId the siteId to be added to the request options (if not a GET)
 * @return {object}      normalised options object
 */
export function makeRequestOptions(opts, siteId) {
  const authToken = store.getState().auth.getIn(["authToken", "id"]);
  const headers = Object.assign(
    { Authorization: authToken },
    opts.body ? { "Content-Type": "application/json" } : {}
  );

  if (
    !(!opts.method || opts.method.toLowerCase() === "get") &&
    opts.body &&
    typeof opts.body !== "string"
  ) {
    opts = Object.assign({}, opts);
    opts.body = Object.assign({}, opts.body, { siteId });
  }

  return Object.assign(
    {
      method: "GET",
      headers
    },
    opts || {},
    {
      body:
        typeof opts.body === "string" ? opts.body : JSON.stringify(opts.body)
    }
  );
}

/**
 * Makes a request to the url given options, adds siteId by default
 * @param  {string}  path       relative resource URI on the server
 * @param  {object}  rawOptions request options (from `request`)
                                noSiteId prevents siteId being sent
 * @return {Promise}            resolves to the parsed results
 */
export function request(path, rawOptions = {}) {
  const options = Object.assign({}, rawOptions);
  const { noSiteId } = options;
  let siteId;

  if (noSiteId) {
    delete options.noSiteId;
  } else {
    siteId = store.getState().settings.getIn(["site", "id"]);
  }

  const requestOptions = makeRequestOptions(options, siteId);
  const querySiteId =
    requestOptions.method.toLowerCase() === "get" ? siteId : null;
  log("> %s %s: %o", options.method, path, requestOptions);

  return fetch(makeFullUrl(path, querySiteId), requestOptions)
    .then(response => {
      if (response.ok) {
        return response.status !== 204 ? response.json() : {};
      } else {
        return response.text().then(body => {
          throw new HttpError(response.status, body);
        });
      }
    })
    .then(response => {
      log("< %s %s: %o", options.method, path, response);
      return response;
    })
    .catch(error => {
      if (error.status === 401 && !options.ignoreAuthErrors) {
        dispatch(authUpdate({ __status: STATUS_PENDING }));
      }

      throw error;
    });
}

export class HttpError {
  constructor(status, body) {
    this.name = "HttpError";
    this.message = body.error;
    this.status = status;
    this.body = body;
    this.stack = new Error().stack;
  }
}
HttpError.prototype = Object.create(Error.prototype);

// Build http verb shortcuts
export const get = (path, options) =>
  request(path, Object.assign({ method: "get" }, options));
export const put = (path, options) =>
  request(path, Object.assign({ method: "put" }, options));
export const post = (path, options) =>
  request(path, Object.assign({ method: "post" }, options));
export const del = (path, options) =>
  request(path, Object.assign({ method: "delete" }, options));
