"use strict";

// auth
export const AUTH_ATTEMPT = "AUTH_ATTEMPT";
export const AUTH_UPDATE = "AUTH_UPDATE";

// settings
export const SETTINGS_UPDATE_DURATION = "SETTINGS_UPDATE_DURATION";
export const SETTINGS_UPDATE_SITE = "SETTINGS_UPDATE_SITE";

// streams
export const STREAMS_ADD = "STREAMS_ADD";
export const STREAMS_SET = "STREAMS_SET";
export const STREAMS_UPDATE = "STREAMS_UPDATE";

// data
export const DATA_ADD = "DATA_ADD";
export const DATA_SET = "DATA_SET";

// last data
export const LASTDATUM_ADD = "LASTDATUM_ADD";
export const LASTDATA_CLEAR = "LASTDATA_CLEAR";

// sensors
export const SENSORS_ADD = "SENSORS_ADD";
export const SENSORS_UPDATE = "SENSORS_UPDATE";
export const SENSORS_SET = "SENSORS_SET";
export const SENSORS_NEW_SET = "SENSORS_NEW_SET";

// sensors registered not reporting
export const SENSORS_NOT_REPORTED_SET = "SENSORS_NOT_REPORTED_SET";

// nodes
export const NODES_SET = "NODES_SET";
export const NODES_UPDATE = "NODES_UPDATE";

// transforms
export const TRANSFORMS_ADD = "TRANSFORMS_ADD";
export const TRANSFORMS_SET = "TRANSFORMS_SET";
export const TRANSFORMS_UPDATE = "TRANSFORMS_UPDATE";
export const TRANSFORMS_NEW_SET = "TRANSFORMS_NEW_SET";

// concrete mix designs
export const CONCRETE_MIX_DESIGNS_SET = "CONCRETE_MIX_DESIGNS_SET";

// metrics
export const METRICS_SET = "METRICS_SET";

// hubs
export const HUBS_SET = "HUBS_SET";

// reset password
export const RESET_PASSWORD_UPDATE = "RESET_PASSWORD_UPDATE";

// users
export const USERS_SET = "USERS_SET";

// sites
export const SITES_SET = "SITES_SET";

// thresholds
export const THRESHOLDS_ADD = "THRESHOLDS_ADD";
export const THRESHOLDS_SET = "THRESHOLDS_SET";
export const THRESHOLDS_NEW_SET = "THRESHOLDS_NEW_SET";
export const THRESHOLDS_FETCH_START = "THRESHOLDS_FETCH_START";
export const THRESHOLDS_FETCH_END = "THRESHOLDS_FETCH_END";

// temporary form
export const TF_THRESHOLDS_ADD = "TF_THRESHOLDS_ADD";
export const TF_THRESHOLDS_DEL = "TF_THRESHOLDS_DEL";
export const TF_THRESHOLDS_CLEAR = "TF_THRESHOLDS_CLEAR";
export const TF_CHOSEN_MIX_SET = "TF_CHOSEN_MIX_SET";
export const TF_START_AT_SET = "TF_START_AT_SET";
export const TF_CONCRETE_STATUS_SET = "TF_CONCRETE_STATUS_SET";
export const TF_CLEAR = "TF_CLEAR";

// subscriptions
export const SUBSCRIPTIONS_SET = "SUBSCRIPTIONS_SET";
export const SUBSCRIPTIONS_UPDATE = "SUBSCRIPTIONS_UPDATE";

// messages
export const ADD_MESSAGE = "ADD_MESSAGE";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";

// pours
export const POURS_SET = "POURS_SET";
export const POURS_ADD = "POURS_ADD";
export const POURS_DELETE = "POURS_DELETE";

// predictions
export const PREDICTIONS_SET = "PREDICTIONS_SET";

// api tokens
export const API_TOKEN_UPDATE = "API_TOKEN_UPDATE";

// alerts
export const ALERTS_RESET = "ALERTS_RESET";
export const ALERTS_DISMISS = "ALERTS_DISMISS";

// organisations and site permissions
export const ORGANISATIONS_SITES_SET = "ORGANISATIONS_SITES_SET";

// mix design performance
export const MIX_DESIGN_PERFORMANCE_SET = "MIX_DESIGN_PERFORMANCE_SET";
