import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { withDevices } from "../../with_devices";

import NavItem from "../nav_item";

// This is so that we can animate the height property since
// it does not animate up to `auto` values. This allows me
// to set the value of the entire options box based on the
// height of each child. For more details, see:
// https://css-tricks.com/using-css-transitions-auto-dimensions/
export const NAV_ITEM_SIZE = 48;

export class NavDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this._toggleOptions = this._toggleOptions.bind(this);

    // This state only applies to mobile
    // On desktop we control visibility through
    // hovering on CSS, not on JavaScript.
    this.state = { isExpanded: false };
  }

  static get propTypes() {
    return {
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ]),
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      dropdownType: PropTypes.oneOf(["userDropdown", "default"]),
      isMobile: PropTypes.bool,
      className: PropTypes.string
    };
  }

  _toggleOptions(e) {
    if (!this.optionBox.contains(e.target)) {
      this.setState({ isExpanded: !this.state.isExpanded });
      e.stopPropagation();

      // This is necessary because the listener on the drawer needs to be on
      // the entire document and therefore is not a React synthetic event.
      e.nativeEvent.stopImmediatePropagation();
    }
  }

  render() {
    const { isExpanded } = this.state;
    const { label, className, children, isMobile, dropdownType } = this.props;

    const dropdownOptions = React.Children.map(
      // we have to do this to filter out any null values (https://github.com/facebook/react/issues/4867)
      React.Children.toArray(children),
      (c, i) => {
        const dropdownHr = c && c.type === "hr" ? "NavDropdown_hr" : null;
        return (
          <div className={classnames("NavDropdown_item", dropdownHr)} key={i}>
            {c}
          </div>
        );
      }
    );

    const isUserDropdown = dropdownType === "userDropdown";

    const normalDropdownHeight = isExpanded
      ? `${dropdownOptions.length * NAV_ITEM_SIZE}px`
      : 0;

    const optionsHeight = {
      height: isUserDropdown
        ? // We cannot simply use zero here because of Safari problems with
          // a combination of different CSS properties and overflow. If we use
          // zero and rely on `overflow` for Safari mobile it won't work.
          "auto"
        : normalDropdownHeight
    };

    return (
      <NavItem>
        <div
          onClick={this._toggleOptions}
          className={classnames("NavDropdown", className)}
        >
          <div className="NavDropdown_label">
            <span className="NavDropdown_labelText">{label}</span>
            <img
              src="../../../../img/downArrow.svg"
              className={classnames(
                "NavDropdown_arrow",
                isMobile && isExpanded ? "NavDropdown_arrowUp" : null
              )}
            />
          </div>
          <div
            data-test-id="nav-dropdown-options"
            ref={o => (this.optionBox = o)}
            style={isMobile ? optionsHeight : null}
            className={classnames(
              "NavDropdown_options",
              `NavDropdown_mobile${isExpanded ? "Visible" : "Hidden"}`
            )}
          >
            {dropdownOptions}
          </div>
        </div>
      </NavItem>
    );
  }
}

export default withDevices(NavDropdown);
