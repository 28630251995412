"use strict";

// Buttons can be:
// - dimension: standard / small (defaults to standard)
// - color: blue / begonia / green (defaults to blue)

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

class Button extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      children,
      color,
      dimension,
      outlined,
      disabled,
      onClick,
      submit,
      href,
      isFullWidth
    } = this.props;

    const clickEvent = e => {
      if (disabled || typeof onClick !== "function") return;
      onClick(e);
    };

    const className = classnames(
      ["Button"]
        .concat(color ? `Button_${color}` : "Button_blue")
        .concat(dimension ? `Button_${dimension}` : "Button_standard")
        .concat(outlined ? "Button_outlined" : null)
        .concat(isFullWidth ? "Button_fullWidth" : null)
    );

    const onClickProp = onClick ? { onClick: clickEvent } : null;
    const hrefProp = !disabled ? { href } : null;

    // If is not a submit, we assume is a button
    const buttonType = (() => {
      if (submit) return { type: "submit" };
      if (!href) return { type: "button" };
      return null;
    })();

    const tagProps = Object.assign(
      { className },
      onClickProp,
      hrefProp,
      buttonType
    );

    const TagType = href ? "a" : "button";
    return (
      <TagType {...tagProps} disabled={disabled}>
        {children}
      </TagType>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  href: (props, propName, componentName) => {
    if (props.onClick && props.href) {
      return new Error(
        `cannot specify both onClick() and href for ${componentName}`
      );
    }
    if (props[propName] && typeof props[propName] !== "string") {
      return new Error(`${propName} must be a string`);
    }
  },
  color: PropTypes.oneOf([
    "blue",
    "green",
    "begonia",
    "yellow",
    "purple",
    "red",
    "black",
    "ghost",
    "ghost-white",
    "white"
  ]),
  dimension: PropTypes.oneOf([
    "standard",
    "medium",
    "small",
    "extra-small",
    "alert"
  ]),
  disabled: PropTypes.bool,
  outlined: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  submit: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Button;
