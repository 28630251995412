import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import NavUser from "../nav_user";
import NavDropdown from "../nav_dropdown";
import NavLink from "../nav_link";
import NavActionItem from "../nav_action_item";

export class UserDropdown extends PureComponent {
  constructor(props) {
    super(props);
  }

  static get propTypes() {
    return {
      userName: PropTypes.string.isRequired,
      currentSiteName: PropTypes.string.isRequired,
      onLogout: PropTypes.func.isRequired,
      withoutPrefs: PropTypes.bool
    };
  }

  render() {
    const { userName, currentSiteName, withoutPrefs, onLogout } = this.props;
    const prefsOption = (
      <NavLink url="/users/settings" className="NavLink_dropdownItem">
        Notification settings
      </NavLink>
    );

    return (
      <NavDropdown
        dropdownType="userDropdown"
        className="UserDropdown_wrapper"
        label={<NavUser userName={userName} siteName={currentSiteName} />}
      >
        {withoutPrefs ? null : prefsOption}
        <hr className="UserDropdown_divider" />
        <NavActionItem onClick={onLogout} className="NavActionItem_logout">
          Logout
        </NavActionItem>
      </NavDropdown>
    );
  }
}

export default UserDropdown;
