import React from "react";
import PropTypes from "prop-types";

import DefaultLayout from "./default_layout";
import TopNavbar from "./top_navbar";

export const SubNavbarLayout = ({ children }) => {
  return (
    <DefaultLayout navbar={<TopNavbar />} hasMessages>
      <div data-test-id="separator" className="SubNavbarLayout_background" />
      {children}
    </DefaultLayout>
  );
};

SubNavbarLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default SubNavbarLayout;
