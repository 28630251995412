"use strict";

import { store } from "./store/store";
import debug from "debug";
import Mixpanel from "mixpanel-browser";
import Countly from "countly-sdk-web";

const log = debug("tracking");

let _countly, _mixpanel;

function initCountly() {
  const key = process.env.COUNTLY_APP_KEY;
  const url = process.env.COUNTLY_SERVER;

  if (!key || _countly) return;

  log("initialising countly");

  Countly.init({
    app_key: key,
    url
  });

  Countly.track_sessions();

  _countly = true;
}

function initMixpanel() {
  const token = process.env.MIXPANEL_TOKEN;

  if (!token || _mixpanel) return;

  log("initialising mixpanel");

  Mixpanel.init(token);

  _mixpanel = true;
}

function setUserData(user) {
  const isBeta = !!process.env.WHITE_VERSION;

  if (_mixpanel && Mixpanel && Mixpanel.people) {
    Mixpanel.identify(user.get("id"));
    Mixpanel.people.set({
      $email: user.get("email"),
      $created: user.get("createdAt"),
      $name: user.get("name"),
      organisationId: user.get("organisationId"),
      isBeta,
      siteId: ""
    });
  }

  if (_countly && Countly && Countly.q) {
    Countly.q.push(["change_id", user.get("id"), true]);
    ["email", "name", "createdAt", "organisationId"].forEach(key => {
      Countly.q.push(["userData.set", key, user.get(key)]);
    });
    Countly.q.push(["userData.set", "isBeta", isBeta]);
  }
}

export function init(userLoginCallback = setUserData) {
  initCountly();
  initMixpanel();

  const { auth } = store.getState();
  if (auth.get("__status") === "STATUS_SAVED") {
    log("user authenticated, setting data for tracking events");
    userLoginCallback(auth.get("user"));
  } else {
    log("awaiting user auth before identifying for event tracking");
    const unsubscribe = store.subscribe(() => {
      const status = store.getState().auth.get("__status");
      if (status === "STATUS_SAVED") {
        init(userLoginCallback);
        unsubscribe();
      }
    });
  }
}

export function track(event, properties) {
  if (_mixpanel) Mixpanel.track(event, properties);
  if (_countly) {
    Countly.q.push([
      "add_event",
      {
        key: event,
        segmentation: properties
      }
    ]);
  }

  log("[event]", event, properties);
}
