import React from "react";
import PropTypes from "prop-types";

import NavDropdown from "../nav_dropdown";
import NavLink from "../nav_link";

export const AnalyseDropdown = ({
  hasActiveMix,
  concretePerformanceFeatureFlag
}) => {
  return (
    <NavDropdown label="Analyse" className="InlineDropdown">
      <NavLink url="/differentials">Temperature Differentials</NavLink>
      {concretePerformanceFeatureFlag && hasActiveMix ? (
        <NavLink url="/concrete_performance">Concrete Performance</NavLink>
      ) : null}
    </NavDropdown>
  );
};

AnalyseDropdown.propTypes = {
  hasActiveMix: PropTypes.bool,
  concretePerformanceFeatureFlag: PropTypes.bool
};

export default AnalyseDropdown;
