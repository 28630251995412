"use strict";

/**
 * Returns the position of an item in an array by binary search
 * @param  {Array}    arr        the array to search
 * @param  {mixed}    item       the item to search for (does not have to be in the array)
 * @param  {Function} comparator a comparison function (a<T>, b<T>) -> ±1. Should return
 *                               -1 if a comes before b, +1 is a comes after b.
 * @return {Number}              the position of the item in the array
 */
export default function bsearch(arr, item, comparator) {
  if (arr.length === 0) {
    return 0;
  }

  const start = 0;
  const end = arr.length;
  const centre = Math.floor((end + start) / 2);
  const a = arr[centre];
  const result = comparator(a, item);

  if (end - start > 1) {
    if (result < 0) {
      return centre + bsearch(arr.slice(centre, end), item, comparator);
    } else {
      return start + bsearch(arr.slice(start, centre), item, comparator);
    }
  } else {
    return start + result < 0 ? 1 : 0;
  }
}

export function bsearchList(list, item, comparator) {
  if (list.size === 0) {
    return 0;
  }

  const start = 0;
  const end = list.size;
  const centre = Math.floor((end + start) / 2);
  const a = list.get(centre);
  const result = comparator(a, item);

  if (end - start > 1) {
    if (result < 0) {
      return centre + bsearchList(list.slice(centre, end), item, comparator);
    } else {
      return start + bsearchList(list.slice(start, centre), item, comparator);
    }
  } else {
    return start + result < 0 ? 1 : 0;
  }
}
