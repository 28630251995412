"use strict";

export const batteryFull = "BATTERY_STATUS_FULL";
export const batteryHalf = "BATTERY_STATUS_HALF";
export const batteryLow = "BATTERY_STATUS_LOW";
export const batteryDead = "BATTERY_STATUS_DEAD";
export const batteryNotReportedYet = "BATTERY_STATUS_NOT_REPORTED_YET";

export const hubOnline = "HUB_STATUS_ONLINE";
export const hubOffline = "HUB_STATUS_OFFLINE";

export const nodeOnline = "NODE_STATUS_ONLINE";
export const nodeOffline = "NODE_STATUS_OFFLINE";
export const nodeInactive = "NODE_STATUS_INACTIVE";

export const sensorOnline = "SENSOR_STATUS_ONLINE";
export const sensorOffline = "SENSOR_STATUS_OFFLINE";
export const sensorUnplugged = "SENSOR_STATUS_UNPLUGGED";
export const sensorNotReported = "SENSOR_STATUS_NOT_REPORTED";

export const hardwareOnline = "HARDWARE_STATUS_ONLINE";
export const hardwareOffline = "HARDWARE_STATUS_OFFLINE";
export const hardwareUnplugged = "HARDWARE_STATUS_UNPLUGGED";
export const hardwareOff = "HARDWARE_STATUS_OFF";
