"use strict";

import { Map } from "immutable";
import { STREAMS_ADD, STREAMS_SET, STREAMS_UPDATE } from "../actions";

const setter = (prev, stream) => prev.set(stream.id, stream);

export default function reducer(state = new Map(), { type, stream, streams }) {
  if (type === STREAMS_ADD) {
    return streams.reduce(setter, state);
  }

  if (type === STREAMS_SET) {
    return streams.reduce(setter, new Map());
  }

  if (type === STREAMS_UPDATE) {
    return state.mergeIn([stream.id], stream);
  }

  return state;
}
