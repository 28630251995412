import React, { PureComponent } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import NavItem from "../nav_item";

/* We need to style react-select using its CSS-in-JS functionality */

const selectorStyles = {
  container: provided => ({
    ...provided,
    width: "100%"
  }),
  control: provided => ({
    ...provided,
    color: "#fff",
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    width: "80%"
  }),
  menu: provided => ({
    ...provided,
    marginTop: "0",
    backgroundColor: "#fff",
    width: "100%",
    borderRadius: "0"
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#026df5" : "#30363e",
    backgroundColor: "#fff"
  }),
  placeholder: provided => ({
    ...provided,
    color: "#fff",
    fontStyle: "italic",
    fontWeight: 500,
    letterSpacing: "0px"
  }),
  singleValue: provided => ({
    ...provided,
    color: "#fff",
    fontStyle: "italic",
    fontWeight: 500,
    letterSpacing: "0px",
    width: "100%",
    marginLeft: "4px"
  }),
  input: provided => ({
    ...provided,
    color: "#fff",
    marginBottom: 0
  }),
  indicatorSeparator: () => ({ display: "none" }),
  dropdownIndicator: () => ({ color: "#fff" })
};

export class NavSelector extends PureComponent {
  constructor(props) {
    super(props);
  }

  static get propTypes() {
    return {
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ]),
      value: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
      }),
      options: PropTypes.array,
      placeholder: PropTypes.string,
      onChange: PropTypes.func
    };
  }

  render() {
    const { options, placeholder, onChange, value } = this.props;
    return (
      <NavItem>
        <Select
          styles={selectorStyles}
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          searchable={true}
          clearable={false}
          closeMenuOnScroll={true}
          escapeClearsValue={true}
          classNamePrefix={"react-select"}
          value={value}
        />
      </NavItem>
    );
  }
}

export default NavSelector;
