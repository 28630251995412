import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Collection } from "immutable";
import head from "lodash/head";

import Navbar from "../navbar";
import { HubOfflineAlert } from "../global_alerts";
import NavLink from "../navbar/nav_link";
import NavSelector from "../navbar/nav_selector";
import FeatureFlag from "../../feature_flag";
import { not, rules, check } from "../../../feature_flag_rules";
import { userShape, siteShape } from "../../../types/shapes";

import SupportDropdown from "../navbar/support_dropdown";
import AnalyseDropdown from "../navbar/analyse_dropdown";
import UserDropdown from "../navbar/user_dropdown";
import { hubOffline } from "../../../constants/statuses";
import { HUB_OFFLINE } from "../../../constants/global_alerts";

import {
  authLogout,
  changeSite,
  getSites,
  getConcreteMixDesigns,
  getHubs,
  dismissAlert
} from "../../../store/action_creators";

import { selectSensor } from "../../../store/selectors";
import intercom from "../../../intercom";

export class TopNavbar extends PureComponent {
  constructor(props) {
    super(props);
    this.changeSite = this.changeSite.bind(this);
    this.logout = this.logout.bind(this);

    this.state = { hubsLoaded: false };
  }

  static get propTypes() {
    return {
      sites: PropTypes.instanceOf(Collection).isRequired,
      alerts: PropTypes.instanceOf(Collection).isRequired,
      currentSite: PropTypes.instanceOf(Collection).isRequired,
      concrete_mix_designs: PropTypes.instanceOf(Collection).isRequired,
      userName: PropTypes.string.isRequired,
      authLogout: PropTypes.func.isRequired,
      getSites: PropTypes.func.isRequired,
      getConcreteMixDesigns: PropTypes.func.isRequired,
      changeSite: PropTypes.func.isRequired,
      dismissAlert: PropTypes.func.isRequired,
      getHubs: PropTypes.func.isRequired,
      params: PropTypes.shape({ sensorId: PropTypes.string }),
      router: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
      location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
      allHubsOffline: PropTypes.bool,
      sensorExists: PropTypes.bool,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ]),
      isSuperUser: PropTypes.bool,
      user: userShape,
      site: siteShape
    };
  }

  logout(e) {
    e.preventDefault();

    this.props.authLogout();
    intercom("shutdown");
  }

  async componentDidMount() {
    this.props.getSites();
    this.props.getConcreteMixDesigns();
    await this.props.getHubs();
    this.setState({ hubsLoaded: true });
  }

  changeSite(option) {
    const siteId = option ? option.value : null;

    this.props.changeSite(siteId);

    // This redirects a user to the index of sensors if the sensor existed
    // This is because the sensor cannot be on any other site and therefore
    // there is no necessity to show an error state.
    if (this.props.sensorExists) this.props.router.push("/sensors");

    // Give react time to dispatch the state change
    // and save to local storage before reloading
    setTimeout(() => global.window.location.reload(), 100);
  }

  _getHubAlert() {
    const { allHubsOffline, alerts, dismissAlert, router } = this.props;

    if (this.props.allHubsOffline && this.state.hubsLoaded) {
      return (
        <HubOfflineAlert
          onAction={() => router.push("/inventory")}
          onDismiss={() => dismissAlert(HUB_OFFLINE)}
          isVisible={alerts.get(HUB_OFFLINE) && allHubsOffline}
        />
      );
    }

    return null;
  }

  render() {
    const {
      userName,
      sites,
      currentSite,
      location,
      params,
      concrete_mix_designs,
      user,
      site
    } = this.props;

    const concretePerformanceFeatureFlag = check(
      rules.concretePerformance,
      user,
      site
    );

    const isActive =
      location.pathname === `/view/${params.sensorId}` ||
      location.pathname === "/register";

    const siteOptions = sites
      .map(site => ({ label: site.name, value: site.id }))
      .toArray();

    const hubAlert = this._getHubAlert();
    const hasActiveMix = concrete_mix_designs.filter(m => !m.draft);

    return (
      <Navbar alert={hubAlert}>
        <NavSelector
          options={siteOptions}
          placeholder={currentSite ? currentSite.get("name") : "Site Name"}
          value={
            currentSite
              ? head(
                  siteOptions.filter(opt => {
                    return opt.value === currentSite.get("id");
                  })
                )
              : null
          }
          onChange={this.changeSite}
        />
        <FeatureFlag rules={not(rules.pours)}>
          <NavLink url="/sensors" isActive={isActive}>
            Sensors
          </NavLink>
        </FeatureFlag>
        <FeatureFlag rules={rules.pours}>
          <NavLink url="/pours" isActive={isActive}>
            Pours
          </NavLink>
        </FeatureFlag>
        <NavLink url="/inventory" alert={hubAlert}>
          Site Inventory
        </NavLink>
        <AnalyseDropdown
          hasActiveMix={hasActiveMix.size > 0}
          concretePerformanceFeatureFlag={concretePerformanceFeatureFlag}
        />
        <SupportDropdown />
        <UserDropdown
          userName={userName}
          currentSiteName={currentSite.get("name")}
          onLogout={this.logout}
        />
      </Navbar>
    );
  }
}

export const connectorFn = (
  { sensors, sites, auth, settings, concrete_mix_designs, hubs, alerts },
  { router, params }
) => ({
  sites,
  router,
  alerts,
  concrete_mix_designs,
  userName: auth.getIn(["user", "name"]),
  currentSite: settings.get("site"),
  sensorExists:
    params && params.sensorId && !!selectSensor({ sensors }, params.sensorId),
  allHubsOffline: hubs.every(({ status }) => status === hubOffline),
  user: auth.get("user").toJSON(),
  site: settings.get("site").toJSON()
});

export default withRouter(
  connect(
    connectorFn,
    dispatch =>
      bindActionCreators(
        {
          authLogout,
          getSites,
          changeSite,
          getConcreteMixDesigns,
          getHubs,
          dismissAlert
        },
        dispatch
      )
  )(TopNavbar)
);
