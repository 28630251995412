import PropTypes from "prop-types";

export const siteShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string
});

export const sensorShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  nodeId: PropTypes.string,
  status: PropTypes.string,
  registeredAt: PropTypes.string,
  registeredBy: PropTypes.number,
  archivedAt: PropTypes.string,
  lastSeen: PropTypes.string,
  lastDatumAt: PropTypes.string
});

export const mixDesignShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  maturityMethod: PropTypes.string.isRequired,
  bestFitFunction: PropTypes.string.isRequired,
  yIntercept: PropTypes.number.isRequired,
  slope: PropTypes.number.isRequired,
  strengthAdjustment: PropTypes.number
});

export const pourShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sensorCount: PropTypes.number
});

export const userShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string,
  email: PropTypes.string.isRequired,
  cookiePolicySignedAt: PropTypes.string,
  tosSignedAt: PropTypes.string,
  organisationId: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  isMultiSite: PropTypes.bool,
  isBeta: PropTypes.bool,
  isSuperUser: PropTypes.bool,
  isBot: PropTypes.bool
});

export const apiTokenShape = PropTypes.shape({
  id: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  expiredAt: PropTypes.string,
  userId: PropTypes.number
});

export const kpiTableDataShape = PropTypes.shape({
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired
});
