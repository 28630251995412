"use strict";

import { combineReducers } from "redux";

import auth from "./auth";
import data from "./data";
import features from "./features";
import nodes from "./nodes";
import sensors from "./sensors";
import settings from "./settings";
import streams from "./streams";
import subscriptions from "./subscriptions";
import temporaryForm from "./temporary_form";
import messages from "./messages";
import thresholds from "./thresholds";
import transforms from "./transforms";
import lastData from "./lastData.js";
import pours from "./pours.js";
import predictions from "./predictions";
import token from "./api_token";
import alerts from "./alerts";
import organisationForSite from "./organisation_for_site";
import mixDesignPerformance from "./mix_design_performance";

import set from "./set";
import mapFromIds from "./map_from_ids";
import { fromJS, Map, OrderedMap } from "immutable";
import {
  HUBS_SET,
  METRICS_SET,
  SITES_SET,
  RESET_PASSWORD_UPDATE,
  SENSORS_NEW_SET,
  CONCRETE_MIX_DESIGNS_SET,
  THRESHOLDS_NEW_SET,
  TRANSFORMS_NEW_SET,
  USERS_SET
} from "../actions";

export default combineReducers({
  // Needed to make redux-persist-migrate work -
  // see https://github.com/wildlifela/redux-persist-migrate/issues/9
  storage: (state = { version: 0 }) => state,

  auth,
  concrete_mix_designs: set(
    CONCRETE_MIX_DESIGNS_SET,
    "mixes",
    mapFromIds,
    new Map()
  ),
  data,
  sensors,
  settings,
  streams,
  transforms,
  thresholds,
  subscriptions,
  temporaryForm,
  messages,
  nodes,
  features,
  lastData,
  pours,
  predictions,
  token,
  alerts,
  organisationForSite,
  mixDesignPerformance,
  hubs: set(HUBS_SET, "hubs", mapFromIds, new Map()),
  metrics: set(METRICS_SET, "metrics", mapFromIds, new Map()),
  new_sensor: set(SENSORS_NEW_SET, "sensor", fromJS, fromJS({})),
  new_threshold: set(THRESHOLDS_NEW_SET, "threshold", fromJS, fromJS({})),
  new_transform: set(TRANSFORMS_NEW_SET, "transform", fromJS, fromJS({})),
  reset_password: set(
    RESET_PASSWORD_UPDATE,
    "resetPassword",
    fromJS,
    fromJS({})
  ),
  sites: set(
    SITES_SET,
    "sites",
    sites => mapFromIds(sites, "id", new OrderedMap()),
    new OrderedMap()
  ),
  users: set(USERS_SET, "users", mapFromIds, new Map())
});
