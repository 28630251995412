import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Transition, animated } from "react-spring";

import { withDevices } from "./with_devices";

import Button from "./button";

const alertIcon = (
  <img className="NavbarAlert_icon" src="./img/alert-critical.svg" />
);

export class NavbarAlert extends PureComponent {
  constructor(props) {
    super(props);
  }

  static get propTypes() {
    return {
      header: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      actionText: PropTypes.string.isRequired,
      onAction: PropTypes.func.isRequired,
      onDismiss: PropTypes.func.isRequired,
      isMobile: PropTypes.bool,
      isVisible: PropTypes.bool
    };
  }

  render() {
    const {
      isMobile,
      isVisible,
      header,
      body,
      actionText,
      onAction,
      onDismiss
    } = this.props;

    const alertContent = (
      <div className="NavbarAlert_wrapper">
        <div className="NavbarAlert_topBar">
          <div className="NavbarAlert_triangleUp" />
        </div>
        <div className="NavbarAlert_bottomPart">
          <h2 className="NavbarAlert_header">
            {alertIcon} {header}
          </h2>
          <div className="NavbarAlert_body">
            <p className="NavbarAlert_bodyText">{body}</p>
            <div className="NavbarAlert_controls">
              <Button
                color="blue"
                onClick={onAction}
                dimension={isMobile ? "medium" : "small"}
                isFullWidth
              >
                {actionText}
              </Button>
              <Button
                data-react-id="dismiss_notification"
                color="ghost"
                onClick={onDismiss}
                dimension={isMobile ? "medium" : "small"}
                isFullWidth
              >
                Dismiss notification
              </Button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <Transition
        items={isVisible}
        config={{ delay: isVisible ? 5000 : 0 }}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {isVisible => props =>
          isVisible && (
            <animated.div style={props}>{alertContent}</animated.div>
          )}
      </Transition>
    );
  }
}

export default withDevices(NavbarAlert);
