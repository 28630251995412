"use strict";

import { Map } from "immutable";
import isEqual from "lodash/isEqual";
import findIndex from "lodash/findIndex";
import matches from "lodash/matches";

/**
 * Select a node from the state.
 *
 * @param {object} state the state object.
 * @param {string} id the id of the node.
 * @return {object}  node properties.
 */
export function selectNode(state, id) {
  return state.nodes.get(id);
}

/**
 * Select a sensor from the state.
 *
 * @param {object} state the state object.
 * @param {string} id the id of the sensor.
 * @returns {object} sensor properties.
 */
export function selectSensor(state, id) {
  return state.sensors.get(id);
}

export const pathIndexOf = pathObj => path => findIndex(path, matches(pathObj));

export const streamPathMatches = path => stream => {
  const apply = f => f(stream.path);
  const mappedToIndices = path.map(pathIndexOf).map(apply);
  if (mappedToIndices.indexOf(-1) > -1) return false;

  const sortedIndices = mappedToIndices.concat().sort();
  return isEqual(mappedToIndices, sortedIndices);
};

/**
 * Selects streams based on their path.
 *
 * @param {object} state the state object.
 * @param {array} path path elements to match (ordered).
 * @returns {Seq} list of matching streams or empty list.
 */
export function selectStreamsWherePathMatches(state, path) {
  return state.streams.filter(streamPathMatches(path));
}

/**
 * Selects the raw stream for a given sensor.
 *
 * @param {object} state the state object.
 * @param {string} sensorId the id of the sensor.
 * @returns {object} the raw stream or null if none exists.
 */
export function selectRawStream(state, sensorId) {
  const all = selectStreamsWherePathMatches(state, [
    { type: "sensor", value: sensorId }
  ]).filter(s => s.path.length === 1);
  if (all.size !== 1) return null;
  return all.first();
}

/**
 * Selects a user by id.
 *
 * @param {object} state the state object.
 * @param {number} id the id of the action to select.
 * @returns {object} action properties.
 */
export function selectUser(state, id) {
  return state.users.get(id);
}

/**
 * Selects a metric by id.
 *
 * @param {object} state the state object.
 * @param {number} id the id of the metric to select.
 * @returns {object} metric properties.
 */
export function selectMetric(state, id) {
  return state.metrics.get(id);
}

/**
 * Selects the transforms for a given stream.
 *
 * @param {object} state the state object.
 * @param {string} streamId id of the stream for which to select.
 * @returns {Map} keyed by transform ID
 */
export function selectTransformsForStream(state, streamId) {
  return state.transforms.get(streamId, new Map());
}

/**
 * Selects a transform by id.
 *
 * @param {object} state the state object.
 * @param {number} id the id of the transform to select.
 * @returns {object} transform properties.
 */
export function selectTransform(state, id) {
  return state.transforms.flatten().get(id);
}

export function selectTranformFromStreamIds(state, ids) {
  return ids
    .map(streamId => selectTransformsForStream(state, streamId))
    .reduce((prev, next) => prev.merge(next), new Map());
}

/**
 * Selects subscriptions for an user.
 *
 * @param {object} state the state object.
 * @param {number} currentUserId the id of the user to select subscriptions for.
 * @returns {object} user subscriptions.
 */
export function selectUserSubscriptions(state, currentUserId) {
  return state.subscriptions.filter(s => s.userId === currentUserId);
}

/**
 * Selects a mix for a stream.
 *
 * @param {object} state the state object.
 * @param {string} streamId id of the stream
 * @returns {object} mix.
 */
export function selectMixForStream(state, streamId) {
  const transform = state.transforms.get(streamId).first();
  return state.concrete_mix_designs.get(transform.concreteMixDesignId);
}

/**
 * Selects a mix for a transform.
 *
 * @param {object} state the state object.
 * @param {object} transform for which to select mix design for
 * @returns {object} mix.
 */
export function selectMixForTransform(state, transform) {
  return state.concrete_mix_designs.get(transform.concreteMixDesignId);
}

/**
 * Tells whether at least one of the passed pieces of state are loading.
 *
 * @param {object} state the state object.
 * @param {object} transform for which to select mix design for
 * @returns {object} mix.
 */
export function isLoading(state, ...stateNames) {
  return stateNames
    .map(stateName => {
      return state[stateName].get("loadingCount") > 0;
    })
    .some(v => v);
}

/**
 * Select a pour from the state.
 *
 * @param {object} state the state object.
 * @param {string} id the id of the pour.
 * @returns {object} pour properties.
 */
export function selectPour(state, id) {
  return state.pours.get(id);
}

/**
 * Selects a mix for a pour.
 *
 * @param {object} state the state object.
 * @param {object} pour for which to select mix design for
 * @returns {object} mix.
 */
export function selectMixForPour(state, pour) {
  return state.concrete_mix_designs.get(pour.concreteMixDesignId);
}
