"use strict";

import { store } from "./store/store";
import debug from "debug";

const log = debug("intercom");
let _initialised;

export default function intercom() {
  if (!_initialised) {
    initWhenReady();
    return null;
  }

  if (arguments.length === 0) {
    return window.Intercom;
  } else {
    if (arguments[0] === "shutdown") _initialised = false;
    return window.Intercom.apply(window.Intercom, arguments);
  }
}

function init() {
  const { auth, settings } = store.getState();
  if (!(auth.get("__status") === "STATUS_SAVED" && settings.get("site"))) {
    return false;
  }

  log("user authed, settings/site ready, setting up intercom");
  const appId = process.env.INTERCOM_APPID;
  const user = auth.get("user");
  const siteId = settings.getIn(["site", "id"]);
  window.intercomSettings = {
    app_id: appId,
    alignment: "left",
    custom_launcher_selector: ".intercomCustomLauncher"
  };

  window.Intercom("boot", {
    app_id: appId,
    name: user.get("name"),
    email: user.get("email"),
    organisationId: user.get("organisationId"),
    createdAt: user.get("createdAt"),
    siteId,
    isBeta: process.env.WHITE_VERSION
  });
  window.addEventListener("hashchange", () => window.Intercom("update"), false);
  _initialised = true;
  return true;
}

function initWhenReady() {
  if (!init()) {
    log("awaiting user auth & settings before setting up intercom");
    const unsubscribe = store.subscribe(() => {
      if (init()) unsubscribe();
    });
  }
}
