"use strict";

import { Map } from "immutable";
import { TRANSFORMS_ADD, TRANSFORMS_SET, TRANSFORMS_UPDATE } from "../actions";

const setter = (prev, transform) =>
  prev.setIn([transform.streamId, transform.id], transform);

export default function reducer(
  state = new Map(),
  { type, transforms, transform }
) {
  if (type === TRANSFORMS_ADD) return transforms.reduce(setter, state);

  if (type === TRANSFORMS_SET) return transforms.reduce(setter, new Map());

  if (type === TRANSFORMS_UPDATE)
    return state.mergeIn([transform.streamId, transform.id], transform);

  return state;
}
