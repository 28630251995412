"use strict";

import { Map, List } from "immutable";
import { insert } from "../../types/immutable_sorted_list";
import { basic, onProperty } from "../../types/comparators";
import { DATA_ADD, DATA_SET } from "../actions";

export const comparator = onProperty(basic, "time");

function setData(state, streamId, data) {
  const filtered = data.filter(datum => datum.streamId === streamId);
  filtered.sort(comparator);
  return state.set(streamId, List(filtered));
}

export default function reducer(state = new Map(), action) {
  switch (action.type) {
    case DATA_ADD:
      // expecting an array of streams
      return state.withMutations(map => {
        if (!action.data || !action.data.forEach) {
          return;
        }

        action.data.forEach(datum => {
          if (!datum) {
            return;
          }

          map.updateIn([datum.streamId], new List(), list =>
            insert(list, datum, comparator)
          );
        });
      });

    case DATA_SET:
      return setData(state, action.streamId, action.data);

    default:
      return state;
  }
}
