"use strict";

/**
 * Generic reducer for a chunk of the state which is just assigned to
 * @param  {string}   actionType   the action type to match
 * @param  {string}   key          the key in the action to find the data
 * @param  {Function} constructor  function to apply to the state before storing it
 * @param  {object}   initialState initial state
 * @return {Function}              reducer
 */
export default function reducer(actionType, key, constructor, initialState) {
  return (state = initialState, action) => {
    return action.type === actionType ? constructor(action[key]) : state;
  };
}
