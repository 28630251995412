import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import NavbarAlert from "../navbar_alert";

export class HubOfflineAlert extends PureComponent {
  constructor(props) {
    super(props);
  }

  static get propTypes() {
    return {
      onAction: PropTypes.func.isRequired,
      onDismiss: PropTypes.func.isRequired,
      isVisible: PropTypes.bool
    };
  }

  render() {
    const { isVisible, onAction, onDismiss } = this.props;
    const header = "Your hub is unplugged, please plug it in.";
    const body =
      "Your HUB is disconnected. The most probable cause for this is the power being unplugged. Please plug it in so you can benefit from real time measurements.";
    const actionText = "Find out more in Site Inventory";

    return (
      <NavbarAlert
        header={header}
        body={body}
        actionText={actionText}
        onAction={onAction}
        onDismiss={onDismiss}
        isVisible={isVisible}
      />
    );
  }
}

export default HubOfflineAlert;
