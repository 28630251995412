import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Collection } from "immutable";

import Navbar from "../navbar";
import SupportDropdown from "../navbar/support_dropdown";
import UserDropdown from "../navbar/user_dropdown";

import { authLogout } from "../../../store/action_creators";

import DefaultLayout from "./default_layout";
import intercom from "../../../intercom";

export class PoliciesLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  static get propTypes() {
    return {
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ]),
      currentSite: PropTypes.instanceOf(Collection).isRequired,
      userName: PropTypes.string.isRequired,
      authLogout: PropTypes.func.isRequired
    };
  }

  logout(e) {
    e.preventDefault();
    this.props.authLogout();
    intercom("shutdown");
  }

  render() {
    const { children, userName, currentSite } = this.props;

    const navbarInstance = (
      <Navbar>
        <SupportDropdown />
        <UserDropdown
          userName={userName}
          currentSiteName={currentSite.get("name")}
          onLogout={this.logout}
        />
      </Navbar>
    );

    return (
      <DefaultLayout navbar={navbarInstance} hasMessages={false}>
        <div data-test-id="separator" className="PoliciesLayout_background" />
        {children}
      </DefaultLayout>
    );
  }
}

export const connectorFn = ({ auth, settings }) => ({
  userName: auth.getIn(["user", "name"]),
  currentSite: settings.get("site")
});

export default connect(
  connectorFn,
  dispatch => bindActionCreators({ authLogout }, dispatch)
)(PoliciesLayout);
