"use strict";

import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers/index";
import debug from "debug";

import { persistStore, autoRehydrate } from "redux-persist";
import immutableTransform from "redux-persist-transform-immutable";
import createMigration from "redux-persist-migrate";

import { fromJS } from "immutable";

import {
  initWebViewStateStream,
  attachRestoreListener
} from "../webview_utils";

// The setup below is so that when we store values on localStorage
// we also send them for the webview in the App to persist. If
// we are using the platform within react-native.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy/handler/apply
const getPropOwner = (obj, prop) => {
  if (obj === null) return null;
  if (obj.hasOwnProperty(prop)) return obj;
  return getPropOwner(Object.getProtototypeOf(obj), prop);
};

const log = debug("store");

const logger = store => next => action => {
  if (typeof action === "function") {
    log("DISPATCH async");
  } else {
    log("DISPATCH %s", action.type, action);
  }

  const result = next(action);
  log("next state", store.getState());
  return result;
};

const devToolsStub = () => f => f;
const devToolsExtension = (window && window.devToolsExtension) || devToolsStub;

const manifest = {
  1: state => {
    // Import from "redux-localstorage" format
    if (Object.keys(state).length !== 0) return state;
    const oldStateJSON = localStorage.converge;
    // Has been a couple of occasions when localstorage cannot be found when using Android OS
    if (!localStorage) return state;
    if (!oldStateJSON) return state;
    try {
      const oldState = JSON.parse(oldStateJSON);
      const settings = fromJS(oldState.settings);
      const auth = fromJS(oldState.auth);
      localStorage.removeItem("converge");
      return { auth, settings };
    } catch (err) {
      log("localstorage: JSONparse error %s - ignoring", err);
      localStorage.removeItem("converge");
      return state;
    }
  },
  2: state => {
    // Switch from organisation to site
    if (Object.keys(state).length === 0) return state;
    const siteId = state.auth.getIn(["user", "organisationId"]);
    const site = state.auth.get("organisation");

    const auth = state.auth
      .deleteIn(["user", "organisationId"])
      .setIn(["user", "siteId"], siteId)
      .delete("organisation")
      .set("site", site);

    return Object.assign({}, state, { auth });
  },
  3: state => {
    // Move site to settings, delete siteId, change isAdmin to isSuperUser
    if (Object.keys(state).length === 0) return state;
    const site = state.auth.get("site");
    const isSuperUser = state.auth.getIn(["user", "isAdmin"]);

    const auth = state.auth
      .delete("site")
      .deleteIn(["user", "siteId"])
      .deleteIn(["user", "isAdmin"])
      .setIn(["user", "isSuperUser"], isSuperUser);

    const settings = state.settings.set("site", site);

    return Object.assign({}, state, { auth, settings });
  }
};

const migration = createMigration(manifest, "storage");

export const store = createStore(
  reducers,
  compose(
    applyMiddleware(logger, thunk),
    migration,
    autoRehydrate(),
    devToolsExtension()
  )
);

const storeConfig = {
  keyPrefix: "converge.",
  whitelist: ["auth", "settings", "storage", "alerts"],
  transforms: [immutableTransform()]
};

initWebViewStateStream(storeConfig);
attachRestoreListener(storeConfig, store);

persistStore(store, storeConfig);

export function dispatch(action) {
  return store.dispatch(action);
}
