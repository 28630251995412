"use strict";

import { Map } from "immutable";
import {
  THRESHOLDS_ADD,
  THRESHOLDS_SET,
  THRESHOLDS_FETCH_START,
  THRESHOLDS_FETCH_END
} from "../actions";

const setter = (prev, threshold) => prev.set(threshold.id, threshold);

const setLoading = (state, prop, fn) => state.set(prop, fn(state.get(prop)));

export default function reducer(state = new Map({ loadingCount: 0 }), action) {
  switch (action.type) {
    case THRESHOLDS_FETCH_START:
      return setLoading(state, "loadingCount", v => v + 1);

    case THRESHOLDS_FETCH_END:
      return setLoading(state, "loadingCount", v => v - 1);

    case THRESHOLDS_ADD:
      return action.thresholds.reduce(setter, state);

    case THRESHOLDS_SET:
      return action.thresholds.reduce(setter, new Map());

    default:
      return state;
  }
}
