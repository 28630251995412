"use strict";

import { Map } from "immutable";
import { POURS_SET, POURS_ADD, POURS_DELETE } from "../actions";

const setter = (prev, pour) => prev.set(`${pour.id}`, pour);
const remover = (prev, pour) => prev.delete(`${pour.id}`);

export default function reducer(state = new Map(), { type, pours }) {
  if (type === POURS_SET) {
    return pours.reduce(setter, new Map());
  } else if (type === POURS_ADD) {
    return pours.reduce(setter, state);
  } else if (type === POURS_DELETE) {
    return pours.reduce(remover, state);
  }
  return state;
}
