"use strict";

import bsearch from "./bsearch";
import { basic } from "./comparators";

/**
 * Mutatively insert items into a sorted array
 * @param  {array}    arr        sorted array in which to insert
 * @param  {array}    items      array of items to insert
 * @param  {Function} comparator function to compare values
 * @return {void}
 */
export function insert(arr, items, comparator) {
  items.forEach(item => {
    const pos = bsearch(arr, item, comparator);
    arr.splice(pos, 0, item);
  });
}

/**
 * Mutatively delete an item from a sorted array
 * @param  {array}    arr        sorted array from which to delete
 * @param  {mixed}    item       the object to delete
 * @param  {Function} comparator function to compare values
 * @return {void}
 */
export function del(arr, item, comparator) {
  const pos = bsearch(arr, item, comparator);

  if (comparator(arr[pos], item) === 0) {
    arr.splice(pos, 1);
  }
}

/**
 * Check if a sorted array has a given item
 * @param  {array}     arr        sorted array to search
 * @param  {mixed}     item       the item for which to search
 * @param  {Function}  comparator function to compare values
 * @return {Boolean}              if the item is found in the array
 */
export function has(arr, item, comparator) {
  const pos = bsearch(arr, item, comparator);

  return comparator(arr[pos], item, comparator) === 0;
}

export default function SortedList(comparator) {
  const _array = [];
  const _obj = {
    __internal__children: _array,
    __isSortedList: true
  };

  comparator = comparator || basic;

  /**
   * Insert an item into the SortedList
   * @param  {mixed}      items the items to insert
   * @return {SortedList}       this instance (chainable)
   */
  _obj.insert = items => {
    insert(_array, items, comparator);
    return _obj;
  };

  /**
   * Deletes an item from the SortedList
   * @param  {mixed}      item the item to delete
   * @return {SortedList}      this instance (chainable)
   */
  _obj.del = item => {
    del(_array, item, comparator);
    return _obj;
  };

  /**
   * Checks if this SortedList has an item
   * @param  {mixed}   item the item for which to search
   * @return {Boolean}      whether the item exists in the SortedList
   */
  _obj.has = item => {
    return has(_array, item, comparator);
  };

  /**
   * Convert to an array (shallow clone)
   * @return {array} array version of the sorted list
   */
  _obj.toArray = () => {
    return _array.map(a => a);
  };

  /**
   * String representation of a SortedList
   * @return {string} string representation of SortedList
   */
  _obj.toString = () => {
    return `SortedList [${_array.toString()}]`;
  };

  /**
   * JSON representation of a SortedList
   * @return {string} JSON representation of SortedList
   */
  _obj.toJSON = () => {
    return JSON.stringify(_array);
  };

  /**
   * Filter items using a predicate function
   * @param  {Function}   fn predicate function
   * @return {SortedList}    new SortedList with filtered items only
   */
  _obj.filter = fn => {
    const newSL = SortedList(comparator);
    _array.filter(fn).forEach(x => newSL.__internal__children.push(x));

    return newSL;
  };

  /**
   * Apply a function to each element in the SortedList
   * @param  {Function} fn function to apply
   * @return {void}
   */
  _obj.forEach = fn => {
    _array.forEach((item, index) => fn(item, index, _obj));
  };

  /**
   * Map one sorted list to another using a function.
   * NB: the order may not be the same depending on the new comparator specified. To
   * preserve order you need to use arrays not SortedLists:
   * mySortedList.toArray().map(fn);
   * @param  {Function}   fn            mapping function
   * @param  {Function}   newComparator a new comparison function (defaults to original
   *                                    comparator)
   * @return {SortedList}               new, mapped SortedList
   */
  _obj.map = (fn, newComparator) => {
    const newSL = SortedList(newComparator || comparator);
    newSL.insert(_array.map(fn));

    return newSL;
  };

  /**
   * Get the comparator function
   * @return {Function} comparator function
   */
  _obj.getComparator = () => {
    return comparator;
  };

  /**
   * Get the size of the collection
   * @return {integer} size of the collection
   */
  _obj.size = () => {
    return _array.length;
  };

  return _obj;
}

/**
 * Create a new SortedList from an array
 * @param  {array}      arr        array from which to create a SortedList
 * @param  {Function}   comparator function to compare values
 * @return {SortedList}            new sorted list
 */
SortedList.fromArray = function fromArray(arr, comparator) {
  return SortedList(comparator).insert(arr);
};

/**
 * Checks if an object is a SortedList
 * @param  {mixed}   other object to test
 * @return {Boolean}       whether the object is a SortedList
 */
SortedList.isInstance = function isInstance(other) {
  return !!other.__isSortedList;
};
