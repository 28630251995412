/* globals document */

import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import classnames from "classnames";

import { withDevices } from "../with_devices";
import BlinkingRedDot from "../blinking_red_dot";
import NavSelector from "./nav_selector";
import UserDropdown from "./user_dropdown";

export class Navbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showDrawer: false };

    this._hideDrawer = this._hideDrawer.bind(this);
    this._toggleDrawer = this._toggleDrawer.bind(this);
  }

  static get propTypes() {
    return {
      isMobile: PropTypes.bool,
      alert: PropTypes.node,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ])
    };
  }

  _updateDrawerState(showDrawer) {
    const { isMobile } = this.props;
    this.setState({ showDrawer }, () => {
      if (!isMobile) return;

      // We need to hide the intercom launcher when the drawer is open on mobile
      // If we don't do this it covers the logout option
      global.window.Intercom("update", {
        hide_default_launcher: showDrawer
      });
    });
  }

  _toggleDrawer() {
    const showDrawer = !this.state.showDrawer;
    this._updateDrawerState(showDrawer);
  }

  _hideDrawer(e) {
    const isDismissNotificationClick = e => {
      return (
        e.target &&
        e.target.dataset &&
        e.target.dataset.reactId === "dismiss_notification"
      );
    };

    const isMenuClick = e => this.menuHandler.contains(e.target);
    const isDrawerItemClick = e => this.drawer.contains(e.target);
    const isNavItemMid = e => this.navItemsMid.contains(e.target);
    const isNavItemRight = e => this.navItemsRight.contains(e.target);

    if (isDismissNotificationClick(e)) {
      return;
    }

    if (
      !e ||
      isNavItemMid(e) ||
      isNavItemRight(e) ||
      (!isMenuClick(e) && !isDrawerItemClick(e))
    ) {
      return this._updateDrawerState(false);
    }
  }

  componentDidMount() {
    document.addEventListener("click", this._hideDrawer, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this._hideDrawer, false);
  }

  render() {
    const { showDrawer } = this.state;
    const { children, alert, isMobile } = this.props;

    const navbarStatusClass = showDrawer ? "Navbar_clickableBg" : null;
    const drawerStatusClass = showDrawer
      ? "Navbar_openDrawer"
      : "Navbar_closedDrawer";

    const navItems = children.filter(c => c.type !== UserDropdown);
    const leftItem = navItems.find(c => c.type === NavSelector);
    const middleItems = navItems.filter(c => c.type !== NavSelector);

    const userDropdownElement = children.filter(c => c.type === UserDropdown);

    const globalAlert = alert
      ? React.cloneElement(alert, { ref: a => (this.globalAlert = a) })
      : null;

    const alertDot = globalAlert && isMobile ? <BlinkingRedDot /> : null;

    return (
      <header className={classnames("Navbar", navbarStatusClass)}>
        <nav className="Navbar_top">
          <div
            className="Navbar_toggle"
            ref={m => (this.menuHandler = m)}
            onClick={this._toggleDrawer}
          >
            <img src="./img/menu_icon.svg" className="Navbar_menuIcon" />
            <span className="Navbar_menuLabel">MENU</span>
            {alertDot}
          </div>

          <img src="./img/logo_ewhite.svg" className="Navbar_brandIcon" />
        </nav>
        {globalAlert}
        <nav
          className={classnames("Navbar_drawer", drawerStatusClass)}
          ref={d => (this.drawer = d)}
        >
          <div className="Navbar_left">
            <span className="Navbar_selector">{leftItem}</span>
            <img
              src="./img/logo_ewhite.svg"
              className={classnames("Navbar_brandIcon", "Navbar_iconDt")}
            />
          </div>

          <div className="Navbar_middle" ref={n => (this.navItemsMid = n)}>
            {middleItems}
          </div>

          <div className="Navbar_right" ref={n => (this.navItemsRight = n)}>
            {userDropdownElement}
          </div>
        </nav>
      </header>
    );
  }
}

export default withDevices(Navbar);
