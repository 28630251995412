"use strict";

import { Map } from "immutable";
import { PREDICTIONS_SET } from "../actions";

export default function reducer(state = new Map(), action) {
  const { type, sensorId, prediction, milestone } = action;
  if (type === PREDICTIONS_SET) {
    const currentSensorPredictions = state.get(sensorId) || Map();
    const nextSensorPredictions = currentSensorPredictions.set(
      milestone,
      prediction
    );
    return state.set(sensorId, nextSensorPredictions);
  }

  return state;
}
