import pick from "lodash/pick";
import { SAVE_STATE } from "./constants/webview_messages";

const withBridge = fn => (...args) => {
  if (window && window.WebViewBridge) {
    return fn.apply(null, args);
  }
};

export const sendWebViewMessage = withBridge((type, payload) => {
  window.WebViewBridge.send(JSON.stringify({ type, payload }));
});

export const webviewListener = withBridge(fn => {
  window.WebViewBridge.onMessage = fn;
});

/**
 * This replaces the use of the `localStorage` with a wrapper function
 * which will stream all the state set in it to the `WebView` so that
 * it can be locally stored in the app.
 *
 * @param   {String[]}  keyPrefix The prefix of the keys set in the localStorage that
 *                                we need to save. This avoids us storing useless data in
 *                                the app.
 */
export const initWebViewStateStream = ({ keyPrefix }) => {
  const originalSetItem = localStorage.setItem;
  const webviewSetStorage = (...args) => {
    const keyName = args && args[0];
    if (typeof keyName === "string" && keyName.startsWith(keyPrefix)) {
      const propName = keyName.split(keyPrefix)[1];

      sendWebViewMessage(SAVE_STATE, { propName, state: args[1] });
    }
    originalSetItem.apply(localStorage, args);
  };

  localStorage.setItem = webviewSetStorage;
};

/**
 * This is the listener which is responsible for transforming the serialized
 * response that comes from the app into a payload for the `persist/REHYDRADTE`
 * action used by `redux-persist` to restore the state of the web-application.
 *
 * We need to also apply all the necessary transforms to deserialize immutableJS
 * data and we need to replace the extra `""` because storing it into the `AsyncStorage`
 * will require us to re-wrap the string. It's weird behaviour but it's the only way
 * we've found of fixing that bug.
 *
 * @param   {Object}    transforms  The transform objects containing the `out` functions
 *                                  that are used by the app to deserialize and adapt data properly.
 * @param   {String[]}  whitelist   The list of relevant properties of state that should be restored.
 * @param   {Object}    store       The store which we'll dispatch the rehydrate action to.
 */
export const attachRestoreListener = ({ transforms, whitelist }, store) =>
  // This listener needs to be attached asynchronously because it
  // requires the webview bridge code to have been injected
  setTimeout(() => {
    // The listener below is for restoring the state
    // from the react-native app, which needs to send
    // serialized data to the WebView which is then
    // responsible for restoring it.
    webviewListener(serializedMessage => {
      const message = Object.entries(
        JSON.parse(serializedMessage.replace(/\"\"/g, '"'))
      );
      const state = message.reduce((accState, [key, immutableStateStr]) => {
        const stateValue = transforms.reduce(
          (acc, t) => t.out(acc),
          immutableStateStr
        );
        return { ...accState, [key]: stateValue };
      }, {});

      store.dispatch({
        type: "persist/REHYDRATE",
        payload: pick(state, whitelist)
      });
    });
  }, 500);
