"use strict";

import { Map } from "immutable";
import { ORGANISATIONS_SITES_SET } from "../actions";

const setter = (prev, organisationForSite) =>
  prev.set(organisationForSite.id, organisationForSite);

export default function reducer(
  state = new Map(),
  { type, organisationForSite }
) {
  switch (type) {
    case ORGANISATIONS_SITES_SET:
      return organisationForSite.reduce(setter, new Map());

    default:
      return state;
  }
}
