"use strict";

import { Map } from "immutable";
import { LASTDATUM_ADD, LASTDATA_CLEAR } from "../actions";

export default function reducer(state = new Map(), { type, datum }) {
  if (type === LASTDATUM_ADD && datum) {
    return state.set(datum.streamId, datum);
  }
  if (type === LASTDATA_CLEAR) {
    return new Map();
  }
  return state;
}
