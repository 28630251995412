import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const NavItem = ({ children, classOverrides }) => {
  return (
    <div className={classnames("NavItem", classOverrides)}>{children}</div>
  );
};

NavItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  classOverrides: PropTypes.string
};

export default NavItem;
