"use strict";

import { Map } from "immutable";

export default function mapFromIds(objects, idKey = "id", initial = new Map()) {
  return objects.reduce(
    (prev, object) => prev.set(object[idKey], object),
    initial
  );
}
