"use strict";

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { clearMessages } from "../../store/action_creators";
import Button from "./button";

const supportedTypes = ["error", "info"];

export const Message = ({ messageType, title, message, clearMessages }) => {
  const isSupportedType = supportedTypes.indexOf(messageType) !== -1;
  if (!isSupportedType) return null;

  return (
    <div className={`Message${messageType ? " " + messageType : ""}`}>
      {title ? (
        <div className="Message_title">
          <p>{title}</p>
        </div>
      ) : null}
      <div className="Message_body">
        <p>{message}</p>
      </div>
      <Button dimension="small" color="white" onClick={clearMessages}>
        OK
      </Button>
    </div>
  );
};

Message.propTypes = {
  messageType: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  clearMessages: PropTypes.func.isRequired
};

export const connectorFn = ({ messages }) => {
  const { messageType, title, message } = messages.toJS();
  return { messageType, title, message };
};

const connectedMessage = connect(
  connectorFn,
  dispatch => bindActionCreators({ clearMessages }, dispatch)
)(Message);

export default connectedMessage;
