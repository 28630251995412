"use strict";

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import { bindActionCreators } from "redux";
import {
  STATUS_SAVED,
  activateBeta,
  authLogout,
  startFullstorySession,
  endFullstorySession
} from "../store/action_creators";
import { track } from "../track";
import intercom from "../intercom";
import ScrollTo from "./scroll_to";
import { isBetaEnvironment } from "../redirect_utils";
import { FS_IGNORED_ORGANISATION_IDS } from "../constants/configs";

export class AppComponent extends PureComponent {
  static get childContextTypes() {
    return {
      track: PropTypes.func.isRequired,
      intercom: PropTypes.func.isRequired
    };
  }

  static get propTypes() {
    return {
      authLogout: PropTypes.func.isRequired,
      isLoggedIn: PropTypes.bool.isRequired,
      startFullstorySession: PropTypes.func.isRequired,
      activateBeta: PropTypes.func.isRequired,
      endFullstorySession: PropTypes.func.isRequired,
      user: PropTypes.instanceOf(Map),
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ])
    };
  }

  getChildContext() {
    return {
      track,
      intercom
    };
  }

  componentDidMount() {
    const { isLoggedIn, user, startFullstorySession } = this.props;

    if (
      isLoggedIn &&
      user &&
      !FS_IGNORED_ORGANISATION_IDS.includes(user.get("organisationId"))
    )
      startFullstorySession();
  }

  componentDidUpdate(oldProps) {
    // only if we're newly logged in do we want to fetch metrics and start fullstory recordings
    if (!oldProps.isLoggedIn && this.props.isLoggedIn) {
      // This is here to avoid recording converge Sessions so that we
      // avoid them counting towards the session limits on FullStory
      const isTrackableUser =
        this.props.user &&
        !FS_IGNORED_ORGANISATION_IDS.includes(
          this.props.user.get("organisationId")
        );
      if (isTrackableUser) {
        this.props.startFullstorySession();
      }

      // This needs to be inside this if clause to avoid multiple requests
      // since we should only trigger when rehydrating the state.
      if (
        this.props.user &&
        !this.props.user.get("isBeta") &&
        isBetaEnvironment()
      ) {
        // This will use the user from the `auth` piece of state, which causes
        // it to not be refreshed unless a new login is made.
        // This is correct according to Sam because we don't want users that had
        // beta toggled off to turn it on again if they accidentally enter beta
        // again. They can, though, activate the beta status again by logging out
        this.props.activateBeta();
      }
    }

    // if we're newly logged out, we want to properly log out
    if (oldProps.isLoggedIn && !this.props.isLoggedIn) {
      this.props.authLogout();
      this.props.endFullstorySession();
    }
  }

  render() {
    return (
      <ScrollTo>
        <div>{this.props.children}</div>
      </ScrollTo>
    );
  }
}

export default connect(
  ({ auth }) => {
    return {
      user: auth.get("user"),
      isLoggedIn: auth.get("__status") === STATUS_SAVED
    };
  },
  dispatch =>
    bindActionCreators(
      { activateBeta, authLogout, startFullstorySession, endFullstorySession },
      dispatch
    )
)(AppComponent);
