"use strict";

import React from "react";
import PropTypes from "prop-types";
import L from "react-loadable";

const Loading = ({ error, pastDelay }) => {
  if (error) {
    return <p>An unexpected error happened. Please try refreshing the page.</p>;
  } else if (pastDelay) {
    return <p>Loading...</p>;
  }

  return null;
};

Loading.propTypes = {
  // Shape does not apply here because we don't use any of the error's properties
  error: PropTypes.instanceOf(Error),
  pastDelay: PropTypes.bool
};

const Loadable = (loader, overrides) => {
  return L(
    Object.assign(
      {
        loader,
        loading: Loading,
        delay: 350
      },
      overrides
    )
  );
};

export default Loadable;
